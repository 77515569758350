footer.l-footer {
    background: #fff;
    color: $black;
    z-index: 10;
    .cms-page-link {
        color: $white;
        font-size: 1rem;
        font-weight: 400;
    }
}

#footer {
    .blockreassurance {
        padding-top: 1.25rem;
        .block-rea {
            .block-icon {
                height: auto;
                img,
                svg {
                    height: 2.5625rem;
                    width: auto;
                }
            }
            .block-title {
                text-transform: uppercase;
                font-size: 1.125rem;
            }
            p {
                font-size: 1rem;
                line-height: 1.625rem;
                margin-bottom: 0;
            }
            &:nth-child(1) {
                order: 0;
            }
            &:nth-child(2) {
                order: 1;
            }
            &:nth-child(3) {
                order: 3;
            }
            &:nth-child(4) {
                order: 4;
            }
        }
        .reviews-spacing {
            order: 2;
            position: relative;
            img {
                width: 175px;
                position: absolute;
                bottom: calc(100% + 10px);
                transform: translateY(50%);
                // trick to center in smaller div than element
                left: -50px;
                right: -50px;
                margin: auto;
            }
        }
    }
    .footer-container {
        color: $white;
        p:not(.alert), a, ul, li {
            color: $white;
            line-height: 162.5%;
        }
        a:hover {
            color: $tertiary;
        }

        .footer__title {
            font-size: 1.125rem;
            font-weight: 700;
            margin-bottom: 0.3125rem;
            line-height: normal;
        }
        ul {
            margin-bottom: 0;
        }
        p:not(.footer__title):not(.block_newsletter_alert),
        a {
            font-size: 14px;

            &.custom-page-link {
                margin-bottom: 0.3125rem;
                display: inline-block;
            }
        }
        div[class^="block-"],
        div[class*=" block-"] {
            padding: 0 1.5625rem;
        }
    }
    .block-address,
    .block-contact {
        .account-list p {
            margin-bottom: 0;
        }
        .company_name {
            text-transform: uppercase;
        }
    }
    .block-newsletter {
        #footer_newsletter {
            .input-group {
                gap: 0.625rem;
            }
            #gdpr_consent {
                font-size: 12px;
                .custom-control-label {
                    padding-top: 3px;
                    color: $white;
                    .rgpd_tooltop {
                        font-size: 18px;
                        cursor: help;
                    }
                }
            }
        }
        .form-control {
            height: 3.125rem;
            padding: 0.625rem 1.25rem;
            &::placeholder {
                text-transform: uppercase;
                color: $black;
            }
        }
        .input-group-append {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            padding: 0.625rem;
            width: 3.125rem;
            height: 3.125rem;
            .btn {
                padding: 0;
                &:hover:not(.disabled):not(:disabled) {
                    .icon {
                        transform: scale(1.05) rotate(-5deg);
                        filter: invert(20%) sepia(10%) saturate(2715%) hue-rotate(70deg) brightness(35%) contrast(90%);
                    }
                }
            }
            .icon {
                width: auto;
                transition: all 0.3s ease-in-out;
            }
        }
    }

    #footer_store_hours p {
        white-space: pre-line;
    }

    .footer-after-container {
        color: $white;
        background-color: $black;
        position: relative;
        padding: 15px 0;
        > .container > .row {
            justify-content: space-between;
            align-items: center;
        }
        .copyright {
            font-size: 12px;
            margin-bottom: 0;
            max-width: fit-content;
        }
        .block-link-0 {
            flex: 1 1 100%;
            max-width: fit-content;
            .footer__title {
                display: none;
            }
            ul {
                display: flex;
                justify-content: center;
                font-size: 12px;
                margin-bottom: 0;
                li:not(:first-child):before {
                    content: "•";
                    display: inline-block;
                    margin: 0 5px;
                }
            }
        }
        .social-sharing {
            justify-content: flex-end;
            max-width: fit-content;
            flex: none; // for Safari
            .social-share-btn {
                margin-bottom: 0;
                display: block;
                padding: 0.625rem;
                background: $white;
                .social_icon.hovered {
                    display: none;
                }
                &:hover {
                    .social_icon {
                        &:not(.hovered) {
                            display: none;
                        }
                        &.hovered {
                            display: inline;
                        }
                    }
                }
            }
        }
    }
}

// < 992px
@include media-breakpoint-down(md) {
    #footer {
        .footer-after-container {
            padding-top: 1.5rem;
            > .container > .row {
                flex-direction: column;
            }

            .social-sharing {
                margin: 0.5rem 0;
            }
            .block-link-0 {
                text-align: left;
                max-width: unset;
                margin-top: 1.125rem;
                ul {
                    flex-direction: column;
                    text-align: left;
                    li:not(:first-child):before {
                        content: none;
                    }
                }
            }
        }
        .footer-container {
            padding-top: 1.25rem;
            div[class^="block-"],
            div[class*=" block-"] {
                padding: 5px 15px;
            }
            .block-link-1,
            .block-address {
                margin-bottom: 10px;
            }
            .footer__title--mobile {
                font-size: 18px;
            }
            ul,
            #footer_store_hours,
            #footer_store_info,
            #footer_newsletter,
            #footer_store_address {
                margin-top: 0.5rem;
            }
        }
        .block-newsletter {
            #footer_newsletter {
                width: 100%;
            }
        }
        .blockreassurance {
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 15px;
            .reviews-spacing {
                position: absolute;
            }
        }
    }
}

// < 768px
@include media-breakpoint-down(sm) {
    #footer {
        .blockreassurance {
            position: relative;
            .block-rea {
                display: flex;
                flex-direction: column;
                text-align: center;
                flex-basis: 50%;
                &:nth-child(even) {
                    padding-right: 0;
                }
                &:nth-child(odd) {
                    padding-left: 0;
                }
                .block-icon {
                    height: auto;
                    width: auto;
                    max-width: 100%;
                    margin: 10px 0;
                    img,
                    svg {
                        max-width: 95px;
                    }
                }
                .block-title {
                    max-width: inherit;
                    height: auto;
                    padding: 0;
                    line-height: 1.3;
                }
                p {
                    line-height: 1.2;
                    font-size: 14px;
                }
            }
            .reviews-spacing {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .footer-container {
            .block-link-1 {
                margin-bottom: 0;
            }
        }
    }

    // OPTI GOOGLE
    #footer {
        .block-newsletter #footer_newsletter #gdpr_consent,
        .footer-container a,
        .footer-container p:not(.footer__title),
        .footer-after-container .block-link-0 ul,
        .blockreassurance .block-rea p,
        .footer-after-container .copyright {
            font-size: 16px;
        }
    }
}
