#cms {
  #content {
    .wrap-page-location {
      line-height: 1.65;

      .location-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        z-index: 9;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1.5rem;
        background: $white;
        margin: 0;
        gap: 1rem;
        a {
          flex: 1 1 50%;
        }
      }

      section {
        margin-bottom: 5rem;
        &:last-child {
          margin-bottom: 0;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 2.5rem;
        }
      }

      a {
        font-size: 1rem;
      }

      h2 {
        margin-bottom: 2.5rem;
        line-height: 1.25;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
          margin-bottom: 1.25rem;
        }
      }

      ul {
        list-style: none;
        padding: 0;
      }

      .col, .col-md-2, .col-md-3 {
        padding: 0;
      }

      .section-categories, .section-choose_rent_equipment,
      .section-opt_rent_equipment, .section-simple_rent_equipment {
        .row {
          gap: 1rem;
          @include media-breakpoint-down(sm) {
            .col {
              flex-basis: auto;
            }
          }
        }
      }

      .card {
        height: 100%;
        border: none;

        &-body {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          a {
            text-align: center;
            display: block;
            width: 100%;
          }
        }

        &-title {
          font-size: 1rem;
          font-weight: 700;
          color: $blue-dark;
        }

        &-link {
          margin-top: auto;
          width: max-content;
          font-weight: 700;
          color: $blue-dark;
        }
      }

      .intro-section {
        background-color: $white;
        .left-column {
          padding: 2.5rem;
          @include media-breakpoint-down(sm) {
            padding: 1.25rem;
          }
        }
        .right-column {
          padding: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .section-categories {
        margin-bottom: 0;
        a {
          text-transform: initial;
        }
        .section-title {
          margin-bottom: 8rem;
          @include media-breakpoint-down(sm) {
            margin-bottom: 4rem;
          }
        }
        .col-md-2 {
          margin-bottom: 9rem;
          @include media-breakpoint-down(sm) {
            margin-bottom: 4rem;
            flex: 1 1 45%;
          }
        }
        .card {
          position: relative;
          border-top: 3px solid transparent;
          transition: all .3s ease;
          img {
            position: absolute;
            width: 70%;
            left: 15%;
            top: 0;
            transform: translateY(-55%);
            transition: all .3s ease;
          }
          &-body {
            align-items: center;
            font-weight: 700;
            padding: 5rem .3125rem 1rem;
          }

          &:hover {
            border-color: $yellow-dark;
            img {
              transform: translateY(-65%);
            }
          }

          @include media-breakpoint-down(sm) {
            .card {
              &-body {
                padding: 3.5rem .25rem .625rem;
                text-align: center;
                font-size: 0.85rem;
                a {
                  font-size: 0.75rem;
                  padding: 0;
                }
              }
            }
          }
        }
      }

      .section-rental_equipments {
        .left-column {
          padding: 2.5rem;
          background-color: $white;
          @include media-breakpoint-down(sm) {
            margin-bottom: 1.25rem;
          }
        }
        ul {
          margin-top: 2.5rem;
          li:not(:last-child) {
            margin-bottom: 2.5rem;
          }
        }
      }

      .section-choose_rent_equipment {
        .card {
          .card-img-top {
            height: 6.25rem;
            width: auto;
            margin-bottom: 1.25rem;
          }
          .card-body {
            .card-title:last-child {
              margin-top: auto;
            }
          }

        }
      }

      .section-simple_rent_equipment {
        @include media-breakpoint-down(sm) {
          .col {
            flex: 1 1 100%
          }
        }
      }

      .section-opt_rent_equipment {
        .card-list {
          margin-top: 1.25rem;
        }
        .card {
          .card-img-top {
            width: 2.5rem;
            margin-bottom: 1.25rem;
          }

        }
      }

      .section-list_rent_equipment {
        a {
          text-decoration: underline;
        }
      }

      .card-list {
        @include media-breakpoint-down(sm) {
          .col {
            flex: 1 1 100%;
          }
        }
      }

    }
  }
}
