.block-category {
    h1 {
        text-align: center;
        margin-bottom: 2.5rem;
        line-height: 1.15;
    }

    .subcategories {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        .wrap-subcategory {
            flex-basis: 16.228rem;
            padding: 6.5rem 1.25rem 1.25rem;

            @include  media-breakpoint-down(sm) {
                flex-basis: 100%;
            }

            .subcategory {
                display: block;
                position: relative;
                padding: 5rem 0.625rem 1.25rem;
                background: $white;
                border-top: 3px solid transparent;
                height: 100%;
                img {
                    position: absolute;
                    width: 80%;
                    left: 10%;
                    top: -6.25rem;
                    transition: all .3s ease;
                    @include media-breakpoint-down(sm) {
                      height: 10.35rem;
                      width: auto;
                      left: 50%;
                      transform: translateX(-50%);
                    }
                }
                .subcategory_name {
                    font-weight: bold;
                    font-size: 15px;
                    text-align: center;
                    padding: 10px 0;
                    margin-bottom: 0;
                    line-height: 1.2;
                    word-break: break-word;
                }
                &:hover {
                    color: $tertiary;
                }
            }

            &:hover {
                .subcategory {
                    border-top: 3px solid $yellow-dark;
                    img {
                        top: -7rem
                    }
                }
            }
        }
    }
}

.product-miniature {
    .highlighted-informations {
        background: transparent;
        gap: 0.5rem;
        &.no-highlight {
            display: none;
        }
        .quick_pictos {
            text-transform: uppercase;
            color: $primary;
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 5px;
        }
        .pureproductpictogram img {
            max-width: 60px;
            max-height: 60px;
        }
    }
    .product-availability {
        margin: 10px -1.25rem 0 -1.25rem;
        background: $wrapper-bg;
        padding: 8px 1.25rem;
        font-weight: bold;
        color: $secondary;
    }
    .av_category_stars {
        display: none;
    }
}

body#category {
    .wrap-products-top {

    }
    #js-product-list-top {
        margin-bottom: 2.5rem;
        .facets_title {
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0;
            white-space: nowrap;
        }
        .total-products {
            margin-right: 0.3125rem;
            font-size: 0.875rem;
            font-weight: 400;
        }
        .sorting {
            margin: 0.3125rem 0;
            padding: 0 0.3125rem;
            font-size: 0.875rem;
            z-index: 10;
        }
        #_desktop_search_filters_wrapper {
            background: $blue-light;
            padding: 0.625rem 2.25rem;
            gap: 1.25rem;
            &.collapse-filter {
                height: 5rem;
                overflow: hidden;
                &.expanded {
                    height: auto;
                }
            }

            @include  media-breakpoint-down(sm) {
                padding: 0;
            }
        }
    }
    #js-product-list-top,
    #offcanvas_search_filter {
        .clear-all-wrapper {
            margin-left: auto;
            margin-right: 15px;
            order: 3;
            background-color: $white;
            z-index: 10;
            .btn--clearfilter {
                margin: 0;
                color: $primary;
                font-weight: bold;
                font-size: 18px;
                display: flex;
                align-items: center;
                height: 30px;
                .material-icons {
                    line-height: 1.3;
                    font-size: 18px;
                    font-weight: bold;
                    margin-left: 5px;
                }
                &:hover {
                    background-color: $tertiary;
                    color: white;
                }
            }
        }
        #search_filters {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            gap: 0.875rem;
            margin-top: auto;

            &:before {
                // recreating border-radius bottom for js-product-list-top ...
                content: '';
                position: absolute;
                width: 100%;
                height: 50px;
                top: -50px;
                border-radius: 8px;
                background: transparent;
                box-shadow: 0 8px 0 0 $wrapper-bg;
            }
            .facet__block {
                padding: 0;
                margin: 0;
                border: none;
                background: transparent;
                &:first-child {
                    padding-left: 0;
                }
                &:nth-child(6) {
                    padding-right: 0;
                }
                .collpase--facet,
                .faceted-slider {
                    padding: 5px;
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                    > li {
                        padding: 0 10px 10px 5px;
                        background-color: $white;
                    }
                    p {
                        margin: 5px 0;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 700;
                        color: $primary;
                    }
                    .ui-widget-header {
                        background: $primary;
                    }
                }
                .custom-select {

                }
                .custom-checkbox {
                    background-color: $white;
                }
            }
        }
    }
    #_desktop_category_footer {
        display: flex;
        flex-wrap: wrap;
        margin: 60px 0;
        #category-name-description {
            background: rgba($primary, .5);
            padding: 45px 30px;
            color: $white;
            p {
                font-size: 26px;
                font-style: italic;
                font-weight: 300;   
            }
            .h1 {
                font-size: 34px;
                font-style: normal;
                font-weight: bold;
                letter-spacing: 1px;
                margin-bottom: 45px;
            }
        }
        .category-cover {
            padding: 0;
            position: relative;
            background-color: $primary;
            &:before {
                content: '';
                background-color: $tertiary;
                width: 10px;
                height: calc(100% + 30px);
                left: -5px;
                top: -15px;
                position: absolute;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    // pagination
    .page-list
    {
        &.pagination {
            gap: 0.625rem;
            flex-wrap: wrap;
            align-items: center;

            .page-item {
                .page-link {
                    background: $white;
                    border-color: $blue-dark;
                    color: $primary;
                    font-weight: bold;
                    font-size: 1.125rem;
                    padding: 0.625rem 1.125rem;
                    line-height: initial;
                    .material-icons {
                        font-size: 24px;
                    }

                    @include media-breakpoint-down(sm) {
                        &.previous, &.next {
                            font-size: 0;
                            color: transparent;
                        }
                    }
                }
                &.active {
                    .page-link {
                        color: $yellow-dark;
                        border-color: $yellow-dark
                    }
                }
                &:hover {
                    .page-link {
                        color: $tertiary;
                    }
                }
            }
        }
    }

}

.category-SEO {
    margin: 30px 0;
    h3 {
        margin-bottom: 15px;
    }
    > p {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 0;
    }
    img {
        max-width: 100%;
    }
}
.category-FAQ {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    line-height: 162.5%;
    max-width: 100%;
    > * {
        width: fit-content;
    }
    h3, .h3 {
        margin-bottom: 30px;
    }
    h2 {
        display: block;
        width: 100%;
        padding: 0.75rem 0;
    }
    .question {
        font-weight: bold;
        font-size: 1.125rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2rem;
        border-bottom: 1px solid $gray-600;
        &:after {
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.125rem;
            width: 3.125rem;
            background: url("../img/plus.svg") center center no-repeat;
            background-size: auto;
            background-color: $white;
        }
        &[aria-expanded="true"] {
            &:after {
                background-image: url("../img/minus.svg");
            }
        }
    }
    .reponse {
        padding: 0 10px;
        margin-bottom: 1rem;
        max-width: 100%;
        p {
            margin-bottom: 0;
        }
    }
}

//@media (min-width: 992px) {
//    .block-category {
//        .subcategories {
//            .subcategory {
//                &:nth-child(7n) {
//                    margin-right: 0;
//                }
//                &:nth-child(7n+1) {
//                    margin-left: 0;
//                }
//            }
//        }
//    }
//}

// < 992px
@include media-breakpoint-down(md) {
    body#category {
        .block-category {
            margin-top: 15px;
            .subcategories {
                .subcategory {
                    margin: 5px 2.5%;
                }
            }
        }

        #js-product-list-top {
            margin-top: 15px;
            margin-bottom: 15px;

            #_desktop_search_filters_wrapper {
                display: none!important;
            }

            .total-products {
                display: flex;
                align-items: center;
                height: 36px;
            }

            .sorting {
                background: none;
                #search_filter_toggler {
                    margin-left: 15px;
                }
            }
        }

        #search_filters {
            .facet__block {
                > div {
                    display: block !important;
                }
            }
        }

        #offcanvas_search_filter {
            .facets_title {
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 0;
            }

            .modal-body {
                .facets_title {
                    display: none;
                }
            }

            .clear-all-wrapper {
                .btn--clearfilter {
                    text-transform: initial;
                    justify-content: center;
                    margin: 1rem;
                }
            }
            #search_filters {
                &:before {
                    content: none;
                }
                .facet__block {
                    .custom-select {
                        box-shadow: none;
                    }
                }
            }
        }
        &.layout-full-width {
            .product-miniature {
                flex: 0 0 33.3333%;
                max-width: 33.3333%;

                .card-body {
                    padding: 15px;
                }
                .product-title {
                    margin-bottom: 5px;
                }
                .add-to-cart {
                    margin-top: .5rem;
                    font-size: 16px;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    body#category {
        .block-category {
            .subcategories {
                .subcategory {
                    width: 16%; // = 5 per line with margins
                    &:nth-child(5n) {
                        margin-right: 0;
                    }
                    &:nth-child(5n+1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

// < 768px
@include media-breakpoint-down(sm) {
    body#category {
        .block-category {
            h3 {
                font-size: 1.25rem;
            }
            .subcategories {
                .subcategory {
                    width: 100%;
                }
            }
        }
        #js-product-list-top {
            .total-products {
                display: none;
            }
            .facets_title {
                display: none;
            }
            .sorting {
                width: 100%;
            }
        }
        .block-category {
            h1 {
                font-size: 28px;
            }
        }
        &.layout-full-width {
            .product-miniature {
                flex: 0 0 100%;
                max-width: 100%;

                .card-body {
                    padding: 10px;
                }
                .product-title {
                    font-size: 16px;
                }
                .add-to-cart {
                    font-size: 13px;
                }
            }
        }
        #_desktop_category_footer {
            #category-name-description {
                padding: 30px;
                p {
                    font-size: 18px;
                }
            }
            .category-cover:before {
                height: 10px;
                width: calc(100% + 30px);
                left: -15px;
                top: -5px;
            }
        }

        .block {
            &.page_home {
                .block_content {
                    padding-top: 6.6rem;
                }
            }
        }
    }

    .category-FAQ {
        h3 {
            font-size: 26px;
        }
        .question {
            font-size: 18px;
        }
    }

    .category-SEO {
        h3 {
            font-size: 24px;
        }
        ul {
            font-size: 14px;
        }
    }
}