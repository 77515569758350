.l-footer {

  .cms-page-link,
  .account-list a{
    color: $text-muted;
    &:hover{
      color: theme-color('primary');

    }
  }
}
.footer-container{
  margin-top: $footer-container-margin-top;
}

.footer__title{
  color:$black;
  &:visited,&:hover{
    color: $black;
  }
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
  }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}
