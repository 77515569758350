// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

@import "mixins";

$white:    #fff !default;
$gray-100: #EFEFEF !default;
$gray-200: #EFEFEF !default;
$gray-300: #DADADA !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #ACACAC !default;
$gray-700: #495057 !default;
$gray-800: #727272 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);
@include generate-color-classes($grays, 'color-gray');

$blue:        #007bff !default;
$blue-dark:   #2F395E !default;
$blue-light:  #B1BCE5 !default;
$indigo:      #6610f2 !default;
$purple:      #6f42c1 !default;
$pink:        #e83e8c !default;
$red:         #dc3545 !default;
$red-dark:    #9E0000 !default;
$red-light:   #FFD2D2 !default;
$orange:      #fd7e14 !default;
$yellow:      #ffc107 !default;
$yellow-dark: #F6AF00 !default;
$yellow-light:#EECC8E !default;
$green:       #28a745 !default;
$green-dark:  #2F891E !default;
$green-light: #ABE5A0 !default;
$teal:        #20c997 !default;
$cyan:        #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
    (
      "blue":       $blue,
      "blue-dark":  $blue-dark,
      "blue-light": $blue-light,
      "indigo":     $indigo,
      "purple":     $purple,
      "pink":       $pink,
      "red":        $red,
      "red-dark":   $red-dark,
      "red-light":  $red-light,
      "orange":     $orange,
      "yellow":     $yellow,
      "yellow-dak": $yellow-dark,
      "yellow-light": $yellow-light,
      "green":      $green,
      "green-dark": $green-dark,
      "green-light":$green-light,
      "teal":       $teal,
      "cyan":       $cyan,
      "white":      $white,
      "gray":       $gray-600,
      "gray-dark":  $gray-800
    ),
    $colors
);
@include generate-color-classes($colors, 'color');

$primary:       $blue-dark !default;
$secondary:     $gray-600 !default;
$tertiary:      $yellow-dark !default;
$success:       #04826B !default;
$success-light: #EDF4F3 !default;
$info:          $cyan !default;
$warning:       #CC800C !default;
$warning-light: #FFF9F0 !default;
$danger:        #E03030 !default;
$danger-light:  #FFECEC !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
$information:   #5564EF !default;
$information-light:#F2F3FF !default;
$rating:        #F2AD30 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":        $primary,
    "secondary":      $secondary,
    "tertiary":       $tertiary,
    "success":        $success,
    "success-light":  $success-light,
    "info":           $info,
    "warning":        $warning,
    "warning-light":  $warning,
    "danger":         $danger,
    "danger-light":   $danger-light,
    "light":          $light,
    "dark":           $dark,
    "information":    $information,
    "information-light":  $information-light,
    "rating":         $rating
  ),
  $theme-colors
);
@include generate-color-classes($theme-colors, 'theme-color');

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$main-breakpoints : (
    mobile: 480px,
    tablet: 768px,
    desktop: 1024px
)!default;

$grid-breakpoints: (
        xxs: 0,
        xs: 414px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1920px,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 560px,
        md: 740px,
        lg: 980px,
        xl: 1160px,
        xxl: 1360px,
        //xxxl: 1860px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           40px !default;

// Components
//
// Define common padding and border radius sizes and more.
$border-radius:      .5rem !default;
$border-radius-lg:   .0.75rem !default;
$border-radius-sm:   .1875rem !default;

$rounded-pill:       6.25rem !default;

$box-shadow:         0 .125rem .1875rem rgba($black, .1) !default;
$box-shadow-sm:      0 .0625rem .125rem rgba($black, .05) !default;
$box-shadow-lg:      0 .1875rem .375rem rgba($black, .1) !default;

$box-shadow-up:      0 -.125rem .1875rem rgba($black, .1) !default;
$box-shadow-up-sm:   0 -.0625rem .125rem rgba($black, .05) !default;
$box-shadow-up-lg:   0 -.1875rem .375rem rgba($black, .1) !default;

// Définition des espacements pour les écrans desktop et mobile
$spacings: (
        xxs: 0.3125rem, // 5px
        xs: 0.625rem,   // 10px
        sm: 1.25rem,    // 20px
        md: 2.5rem,     // 40px
        lg: 5rem,       // 80px
        xl: 7.5rem      // 120px
);

// Espacements pour la version mobile
$spacings-mobile: (
        xxs: 0.3125rem,  // 5px
        xs: 0.625rem,    // 10px
        sm: 1.25rem,     // 20px
        ms: 1.875rem,    // 30px
        lg: 3.75rem,     // 60px
        xl: 5rem         // 80px
);

// Appliquer les marges et paddings pour les écrans desktop
@each $key, $value in $spacings {
  // Classes margin
  .margin-#{$key} {
    margin: $value;
  }

  .margin-#{$key}-top {
    margin-top: $value;
  }

  .margin-#{$key}-bottom {
    margin-bottom: $value;
  }

  .margin-#{$key}-start {
    margin-inline-start: $value;
  }

  .margin-#{$key}-end {
    margin-inline-end: $value;
  }

  // Classes padding
  .padding-#{$key} {
    padding: $value;
  }

  .padding-#{$key}-top {
    padding-top: $value;
  }

  .padding-#{$key}-bottom {
    padding-bottom: $value;
  }

  .padding-#{$key}-start {
    padding-inline-start: $value;
  }

  .padding-#{$key}-end {
    padding-inline-end: $value;
  }
}

// Appliquer les marges et paddings pour les écrans mobiles
@media screen and (max-width: 575.98px) {
  @each $key, $value in $spacings-mobile {
    // Classes margin pour mobile
    .margin-#{$key} {
      margin: $value;
    }

    .margin-#{$key}-top {
      margin-top: $value;
    }

    .margin-#{$key}-bottom {
      margin-bottom: $value;
    }

    .margin-#{$key}-start {
      margin-inline-start: $value;
    }

    .margin-#{$key}-end {
      margin-inline-end: $value;
    }

    // Classes padding pour mobile
    .padding-#{$key} {
      padding: $value;
    }

    .padding-#{$key}-top {
      padding-top: $value;
    }

    .padding-#{$key}-bottom {
      padding-bottom: $value;
    }

    .padding-#{$key}-start {
      padding-inline-start: $value;
    }

    .padding-#{$key}-end {
      padding-inline-end: $value;
    }
  }
}

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-size-base: 1rem !default; // Taille de base de police, souvent 16px par défaut dans les navigateurs
$breakpoint-font-size: (
        md: 1200px,
        sm: 576px,
) !default;

$font-sizes: (
        h1: (
                default: $font-size-base * 2.5,
                md: $font-size-base * 2,
                sm: $font-size-base * 1.875
        ),
        h2: (
                default: $font-size-base * 1.875,
                md: $font-size-base * 1.8,
                sm: $font-size-base * 1.75
        ),
        h3: (
                default: $font-size-base * 1.75,
                md: $font-size-base * 1.5,
                sm: $font-size-base * 1.375
        ),
        h4: (
                default: $font-size-base * 1.375,
                md: $font-size-base * 1.225,
                sm: $font-size-base * 1.125
        ),
        h5: (
                default: $font-size-base * 1.125,
                md: $font-size-base,
                sm: $font-size-base
        ),
);

// Boucle pour appliquer les tailles de police selon les breakpoints
@each $element, $sizes in $font-sizes {
  #{$element}, .#{$element} {
    // Taille par défaut
    font-size: map-get($sizes, default);

    // Parcourir les breakpoints et appliquer les tailles de police
    @each $breakpoint, $width in $breakpoint-font-size {
      @if map-has-key($sizes, $breakpoint) {
        @media (max-width: #{$width}) {
          font-size: map-get($sizes, $breakpoint);
        }
      }
    }
  }
}

// Définir les tailles de police en px
$font-sizes: (
        1: 0.625rem, // 10px
        2: 0.75rem,  // 12px
        3: 0.875rem, // 14px
        4: 1rem,     // 16px
        5: 1.125rem, // 18px
        6: 1.25rem,  // 20px
        7: 1.5rem,   // 24px
        8: 1.625rem, // 26px
        9: 1.75rem,  // 28px
        10: 1.875rem, // 30px
        11: 2rem,    // 32px
        12: 2.125rem, // 34px
        13: 2.25rem, // 36px
        14: 2.625rem  // 42px
);

// Appliquer les tailles de police aux classes correspondantes
@each $key, $value in $font-sizes {
  .fs-#{$key} {
    font-size: $value;
  }
}

// Définir les valeurs de font-weight
$font-weights: (
        400: 400,   // Normal
        500: 500,   // Medium
        600: 600,   // Semi-Bold
        700: 700,   // Bold
        800: 800,   // Extra-Bold
        900: 900    // Black
);

// Appliquer les font-weights aux classes correspondantes
@each $key, $value in $font-weights {
  .fw-#{$key} {
    font-weight: $value;
  }
}

// Options
$enable-rounded:            false;
$enable-shadows:            true;

//
// Links
//
// Style anchor elements.


$link-hover-decoration:     none;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family:        'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`


$h1-font-size:                42px;
$h2-font-size:                30px;
$h3-font-size:                20px;
$h4-font-size:                18px;
$h5-font-size:                16px;
$h6-font-size:                $font-size-base * 0.9375;

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;

$small-font-size:             $font-size-base * 0.875;


// Components
//
// Define common padding and border radius sizes and more.

$box-shadow:                  2px 2px 8px 0 rgba($black,.2);

// Navs

$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;
// Pagination

$pagination-border-color:           $gray-400;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;

// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;