#manufacturer,
#supplier {
    .brand_cont {
        display: grid;
        grid-template-columns: repeat(
            auto-fill,
            minmax(200px, 1fr)
        ); //ajuster en fonction de la taille choisie pour les images clients
        gap: 30px;

        .brand-img {
            img {
                width: 100%;
                height: auto;
            }
        }
        a:hover {
            color: $tertiary;
        }
    }

    #products{
        margin-top: 30px;
    }

    .brand-infos {
        text-align: center;
        .product-title {
            text-align: center;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
    .brand-products {
        text-align: center;
    }
}
