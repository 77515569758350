#checkout {
  #header {
    .header-top {
      padding: 1.15rem 0;
    }
    @include media-breakpoint-down(sm) {
      .header-nav {
        max-height: 3.5rem;
      }

      #custom-text {
        font-size: .85rem;
        line-height: 1.25;
      }
    }
  }
}