#index {
    .main-slider {
        min-height: 22.5rem;

        a {
            color: $white;
            display: block;
        }

        @include media-breakpoint-up(sm) {
            .slick-slide {
                &.slick-active:hover {
                    a {
                        .right-slider {
                            img {
                                right: -2.5rem;
                            }
                        }
                    }
                }
            }
        }

        .wrap-slider-content {
            display: flex;
            justify-content: space-between;
            background-color: $blue-dark;
            color: $white;
            height: 100%;
        }

        .wrap-left-slider {
            max-width: 100%;
        }

        .left-slider {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 2.5rem 1rem 2.5rem 6.5rem;
        }

        .slider-caption {
            width: 31.25rem;
            max-width: 100%;

            .caption-title {
                color: $white;
            }

            .caption-description {
                line-height: 162.5%;
            }

            .caption-tags {
                display: inline-block;
                font-size: 0.875rem;
                background-color: $green-dark;
                border-radius: 1rem;
                padding: 0.3125rem 0.625rem;
            }

            .caption-action {
                padding: .625rem 1.25rem;
            }
        }

        .wrap-right-slider {
            flex-basis: 40%;
        }

        .right-slider {
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left;
                position: relative;
                right: -5rem;
                transition: all .3s ease;
            }
        }

        .slick-list {
            padding: 0;

            .slick-slide {
                > div {
                    height: 100%;
                    > a {
                        height: 100%;
                    }
                }
            }
        }

        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.slick-next {
                right: 1rem;
                left: unset;
            }

            &.slick-prev {
                left: 1rem;
                right: unset;
            }

            i {
                opacity: 0;
                font-size: 0;
                line-height: normal;
            }
        }

        @include media-breakpoint-down(sm) {
            .wrap-slider-content {
                flex-direction: column;
                height: 100%;
            }

            .wrap-right-slider {
                flex-basis: auto;
            }

            .left-slider {
                padding: 1.25rem;
            }

            .slider-caption {
                .caption-title {
                  font-size: 1.875rem;
                }
            }

            .caption-description {
                font-size: 0.875rem;
            }

            .caption-action {
                width: 100%;
                font-size: 1rem;
            }

            .slick-slide {
                > div {
                    height: 100%;
                    > a {
                        height: 100%;
                    }
                }
            }
        }
    }
}

.home-message {
    padding: 30px 15px;
    border-radius: 15px;
    text-align: center;
    background-color: rgba($secondary, 0.5);
    margin-top: -3rem;
    p {
        margin-bottom: 0;
    }
    .home-message-title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
        color: $primary;
        margin-bottom: 0.5rem;
        position: relative;
        padding: 0 50px;
        .close-message {
            position: absolute;
            right: 0;
            top: 15px;
            transform: translateY(-50%);
            border-radius: 50%;
            background-color: $yellow-dark;
            font-size: 32px;
            font-weight: normal;
            height: 50px;
            width: 50px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background-color: $primary;
                color: white;
            }
        }
    }
}

.home-block-cont {
    height: 375px;
    padding: 15px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    .home-block-ribbon {
        border-radius: 50px;
        background-color: rgba($primary, 0.8);
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        p {
            margin-bottom: 0;
            padding: 15px;
            line-height: 1;
        }
        .btn {
            border-radius: 50px;
            background-color: $white;
            color: $primary;
            padding: 15px 35px;
            font-weight: bold;
            font-size: 18px;
            &:hover {
                background-color: $yellow-dark;
                color: $white;
            }
        }
    }
}

.blockreassurance_row {
    justify-content: space-between;
    .reaitemcont {
        padding: 0 10px;
    }
}

#homecategory_reassurance {
    padding-top: 5.625rem;
    .reaitemcont {
        a.reaitem {
            position: relative;
            display: block;
            border: 0.1875rem solid transparent;
            padding: 5rem 0.625rem 1.25rem 0.625rem;
            background: $white;
            transition: all .3s ease;
            height: 100%;
            &:hover {
                border-top: 0.1875rem solid $yellow-dark;
                box-shadow: 0 2px 16px rgba(0,0,0,.15);
                .block-txt .block-title {
                    color: $yellow-dark;
                }
                .block-icon img {
                    transform: translateY(-1rem);
                }
            }
        }

        .block-icon {
            position: absolute;
            top: 0;
            left: 50%;
            width: auto;
            height: auto;
            margin: 0;
            max-width: 80%;
            transform: translate(-50%, -55%);

            img {
                width: auto;
                height: auto;
                aspect-ratio: 1/1;
                object-fit: contain;
                transition: 300ms ease-in-out;
            }
        }
        .block-txt {
            .block-title {
                font-size: 1rem;
                color: $primary;
                line-height: 162.5%;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        padding-top: 0;

        .slick-list {
            .slick-slide {
                padding: 4.6875rem 0 1rem;
                > div {
                    height: 100%;
                }
            }
        }
        .reaitemcont {
            height: 100%;
            .block-icon {
                img {
                    width: 6.875rem;
                }
            }
        }
    }
}

#homeservices_reassurance {
    .reaitemcont {
        .reaitem {
            display: flex;
            flex-direction: row;
            background-color: $white;
            height: 100%;
            border-top: 3px solid transparent;

            .block-icon {
                overflow: hidden;
                flex: 1 1 55%;
                background-repeat: no-repeat;
                background-size: auto 100%;
                background-position: -1.5rem center;
                transition: all .3s ease;
            }

            .block-txt {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                flex: 1 1 55%;
                .block-title {
                    font-weight: 700;
                    color: $primary;
                    line-height: 162.5%;
                }

                .more-infos {
                    img:last-child {
                        display: none;
                    }
                }
            }

            &:hover {
                border-top: 3px solid $yellow-dark;

                .block-icon {
                    background-position: -0.85rem center;
                }

                .block-txt {
                    .more-infos {
                        img {
                            display: none;
                            &:last-child {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

    }
}

#homeshop {
    display: flex;
    height: 325px;
    .homeshop_txt {
        color: $white;
        background-color: $primary;
        font-size: 26px;
        font-style: italic;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
        p {
            margin-bottom: 0;
        }
    }
    .homeshop_img {
        position: relative;
        padding: 0;
        .homeshop_separator {
            position: absolute;
            left: 0;
            top: -15px;
            background-color: $yellow-dark;
            width: 8px;
            height: calc(100% + 30px);
            z-index: 10;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:after {
            content: "";
            background-color: #a1a1a1;
            position: absolute;
            inset: 0;
            opacity: 0.5;
        }
    }
}

#shop_reassurance {
    .reaitem {
        margin-top: $spacer-y;
        padding: 15px;
        .block-icon {
            height: auto;
            img,
            svg {
                height: 90px;
                max-width: 100%;
            }
        }
        .block-txt {
            margin-top: 15px;
            .block-title {
                font-size: 18px;
                font-weight: normal;
                line-height: 1.1;
            }
        }
    }
}

.section-seo-home, .section-seo-category  {
    width: 100%;
    margin-bottom: 1.25rem;

    .left-content {
        padding: 1.25rem;
        h2 {
            font-size: 1.875rem;
            text-transform: uppercase;
        }
        p {
            font-size: 1rem;
            line-height: 1.25;
            margin: 1.25rem 0;
        }
        a {
            display: block;
            float: left;
            background: $white;
            border: 1px solid;
            padding: 0.625rem 1.25rem;
        }
    }

    .right-content {
        background: url('../img/welcom.png') no-repeat top center, $white;
        background-size: cover;
        min-height: 20rem;
    }
}

// < 992px
@include media-breakpoint-down(md) {
    #homeservices_reassurance {
        .reaitemcont {
            padding: 15px;
        }
    }
    #homeshop {
        .homeshop_txt {
            font-size: 24px;
        }
    }
    .home-link-blocks {
        .row > div:not(:last-child) > .home-block-cont {
            margin-bottom: $spacer;
        }
    }
}

// < 768px
@include media-breakpoint-down(sm) {
    .home-message {
        .home-message-title {
            font-size: 18px;
            line-height: 1.2;
            padding: 0;
            .close-message {
                top: -5px;
                font-size: 22px;
                height: 30px;
                width: 30px;
            }
        }
        p > span {
            font-size: 18px !important;
        }
    }
    .home-block-cont {
        .home-block-ribbon {
            padding: 5px 15px;
            font-size: 16px;
            .btn {
                padding: 5px 15px;
                font-size: 16px;
            }
        }
    }
    #homecategory_reassurance {
        .reaitemcont {
            width: 50%;
            padding: 10px;
            &:nth-child(even) {
                padding-right: 0;
            }
            &:nth-child(odd) {
                padding-left: 0;
            }
            .block-icon {
            }
            .block-txt {
                text-align: center;
                margin-top: 0;
                .block-title {
                    font-size: 16px;
                    max-width: inherit;
                }
            }
        }
    }
    #homeservices_reassurance {
        .reaitemcont {
            width: 50%;
            padding: 8px;
            &:nth-child(even) {
                padding-right: 0;
            }
            &:nth-child(odd) {
                padding-left: 0;
            }
            .block-icon {
                width: auto;
                height: auto;
                img,
                svg {
                    width: auto;
                    height: auto;
                }
            }
            .block-txt {
                padding-left: 0;
                .block-title {
                    height: auto;
                    max-width: inherit;
                    text-align: right;
                    margin: 10px 0;
                    font-size: 0.875rem;
                    padding-top: 0;
                }
                .btn {
                    font-size: 13px;
                }
            }
        }
    }
    #shop_reassurance {
        padding: 0;
        .reaitem {
            margin-top: 0;
            display: flex;
            align-items: center;
            padding-left: 0;
            padding-right: 0;
            .block-icon {
                width: auto;
                margin: 15px;
                img,
                svg {
                    min-width: 60px;
                    width: 60px;
                    height: auto;
                }
            }
            .block-txt {
                height: auto;
                margin-top: 0;
                .block-title {
                    height: auto;
                    max-width: inherit;
                    font-size: 16px;
                    padding-top: 0;
                }
            }
        }
    }
    #homeshop {
        height: auto;
        flex-direction: column;
        .homeshop_txt {
            font-size: 20px;
        }
        .homeshop_img {
            .homeshop_separator {
                top: 0;
                left: -15px;
                height: 8px;
                width: calc(100% + 30px);
            }
        }
    }
}
