#cart {
    .cart-grid-title {
        .card-header {
            background: transparent;
            border: none;
        }
    }
    .cart-grid-body  {
        .cart-container, .card-header, .card-body {
            background: $white;
            border: none;
        }
    }
    .cart-items {
        background-color: $white;
        .cart-item {
            border-radius: 5px;
            overflow: hidden;
            background: $white;
            padding: 0;
            .media {
                height: 100%;
                align-items: center;
                justify-content: center;
                @include media-breakpoint-down(sm) {
                    align-items: flex-start;
                }
            }
            .product-line__img {
                margin-right: 0;
                width: 6.25rem;
                height: auto;
                object-fit: cover;
                @include media-breakpoint-down(sm) {
                    width: 5rem;
                    height: auto;
                }
            }
            .product-line__body {
                padding: 1.25rem 1rem;
                .product-line__title {
                    font-weight: bold;
                    text-transform: uppercase;
                    color: $primary;
                    &:hover {
                        color: $tertiary;
                    }
                }
                > .small span:not(.value) {
                    font-weight: bold;
                }
                .product-price {
                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
            .product-line-actions {
                padding: 1.25rem 1rem;
                height: 100%;

                .input-group {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $gray-200;
                    box-shadow: none;
                    max-width: unset;
                    .input-group-prepend,
                    .input-group-append,
                    .js-cart-line-product-quantity {
                        background: transparent;
                        width: 3.125rem;
                        height: 3.125rem;
                        max-width: unset;
                        .btn {
                            background: none;
                            width: 100%;
                            height: 100%;
                            font-weight: bold;
                            &:hover {
                                background: $primary;
                                color: $white;
                            }
                        }
                    }
                    .input-group-prepend {
                        grid-area: minus;
                    }
                    .input-group-append {
                        grid-area: plus;
                    }
                    .js-cart-line-product-quantity {
                        grid-area: input;
                    }
                }
                .remove-from-cart:hover {
                    color: $tertiary;
                }
            }
        }
    }
    .payplugOneyCta_wrapper {
        margin-bottom: 0;
    }
    .form__add-voucher {
        .input-group {
            flex-direction: column;
            .form-control {
                width: 100%;
                margin-bottom: 1.125rem;
            }
            button {
                width: 100%;
            }
        }
    }
}

.wrap-product-reassurance-cart {
    br {
        display: none;
    }
    .reassurance-security, .reassurance-delivery, .reassurance-return {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
        margin-bottom: 1.25rem;
        &:before {
            content: "";
            display: inline-block;
            position: relative;
            width: 1.25rem;
            min-width: 1.25rem;
            height: 1.25rem;
            background-color: transparent;
        }
    }
    .reassurance-security {
        &:before {
            background: url("../img/security.svg") center center no-repeat;
            background-size: contain;
        }
    }
    .reassurance-delivery {
        &:before {
            background: url("../img/delivery.svg") center center no-repeat;
            background-size: contain;
        }
    }
    .reassurance-return {
        &:before {
            background: url("../img/retour.svg") center center no-repeat;
            background-size: contain;
        }
    }
    .wrap-infos {
        display: flex;
        flex-direction: column;
        .reassurance-description {
            color: $gray-600;
            font-size: 0.875rem;
        }
    }
}

.cart-summary, #js-checkout-summary {
    background: $white;
    .cart-summary-totals .cart-total {
        padding: 0;
        background: none;
        margin-bottom: 1rem;
    }
    .cart-detailed-totals {
        line-height: 1.75;
    }
    .cart-voucher {
        margin: 0 (-$card-spacer-x);
        background: $blue-light;
        padding: $card-spacer-x;
        position: relative;
        overflow: hidden;
        line-height: 1.15;
        .block-promo {
            position: inherit;
            z-index: 1;
        }
        .promo-code-button {
            margin-bottom: 0;
            font-weight: bold;
            text-transform: initial;
            text-decoration: underline;
            &:hover {
                a {
                    color: $blue-dark;
                }
            }
        }
        .promo-code {
            margin: 0;
        }
        .promo-code__content {
            background: none;
            padding: 0;
        }
        .promo-discounts, .promo-discounts label {
            margin-bottom: 0;
        }
    }
    .card-footer {
        background: $white;
        padding: 1.25rem;
    }
}

.cart-detailed-totals {
    #cart-subtotal-discount, #cart-subtotal-shipping {
        .value {
            text-transform: uppercase;
            color: $green-dark;
            font-weight: 700;
        }
    }

    .cart-summary-totals {
        border-top: 1px solid $gray-300;
        padding-top: 0.625rem;
        margin-top: 0.625rem;
    }
}

// < 992px
@include media-breakpoint-down(md) {
    #cart {
        .cart-summary {
            margin-top: 0;
            .card-footer {
                border: none;
            }
            .cart-voucher {
                &:before {
                    left: -25%;
                }
                &:after {
                    right: -25%;
                }
            }
        }
    }
}
// < 768px
@include media-breakpoint-down(sm) {
    #cart {
        .cart-summary {
            .cart-voucher {
                &:before {
                    left: -60%;
                }
                &:after {
                    right: -60%;
                }
            }
        }
        .cart-items {
            .cart-item {
                .product-line-grid-right {
                    padding: 0;
                }
            }
        }

    }
}