  button {
    &[data-action="show-password"] {
      background: $primary;
      color: $white;
      margin-left: 0.5rem;
      &:hover {
        background: $yellow-dark;
        color: $white;
      }
    }
  }

  table.table {
    thead {
      tr {
        th {
          border-top: none;
        }
      }
    }
  }

  .wrap-footer-customer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    a, span {
      text-decoration: underline;
      line-height: 1.25;
    }
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  .wrap-content-customer {
    padding: 2.5rem;
    background: $white;
    border-radius: 0;
    @include media-breakpoint-down(sm) {
      padding: 0.5rem;
    }
  }

  #history, #order-detail, #module-psgdpr-gdpr {
    .page-content {
      @extend .wrap-content-customer;
    }
  }

  .wrap-customer-registration {
    @extend .wrap-content-customer;
    @include media-breakpoint-down(sm) {
      .page-wrapper {
        padding: 0 1.25rem 1.25rem;
      }
    }
  }

  .small-container {
    max-width: 53.75rem;
    margin: 0 auto;
    border-radius: 0;
  }

  body#address {
    #content {
      @extend .small-container;
    }
  }

  .page-content {
    &--module-psgdpr-gdpr {
      .wrap-action {
        a {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .address {
    .address-header {
      font-size: 1rem;
      font-weight: 700;
    }

    .address-footer {
      font-weight: 700;
      color: $black;
      justify-content: center;
      gap: 1.75rem;
      border-top: 1px solid $gray-300;
    }
  }

  .page-content--history {
    .badge {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  #order-detail {
    .btn-reorder {
      position: absolute;
      right: 0;
      top: 0;
      @include media-breakpoint-down(sm) {
        position: relative;
        font-size: 1rem;
        width: 100%;
        margin: 1.5rem 0;
      }
    }

    #order-infos {
      .box {
        line-height: 2rem;
      }
    }
  }

