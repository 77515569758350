$transition_header: all 300ms ease-in-out;

.l-header {
    // background-color: $header-bg;
    margin-bottom: $spacer;
    // box-shadow: $header-box-shadow;
}
#header {
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1030;
    max-width: 100vw;
    &.header-minimize {
        .header-nav {
            max-height: 0;
            padding: 0;
        }
        .header-top {
            padding-top: 0.625rem;
            padding-bottom: 5px;
        }
        .header-contact-rich-cont {
            padding-top: 13px;
        }
    }
}

.header__up {
    align-content: center;
}

.header-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    transition: $transition_header;
}

.header__search {
    flex-grow: 1;
    padding-left: 5rem;
    .search-widget {
        width: 33.125rem;
        height: 3.125rem;
        max-width: 100%;
        margin: unset;

        &__btn {
            width: 3.125rem;
            height: 100%;
            padding: .625rem;
            background: url("../img/search.svg") center center no-repeat;
            background-color: $blue-dark;
        }
    }
}
.header__right {

}
.header__right,
.header-nav__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.header__rightitem:not(:last-child) {
    margin-right: 1.25rem;
}
.header__logo {
    padding: 0;
    .logo {
        height: 4.875rem;
        transition: $transition_header;
    }
}
.header__logo--checkout {
    max-width: 200px;
}
.header-nav--checkout {
    padding-top: $spacer;
    padding-bottom: $spacer;
}
#menu-icon {
    font-size: 32px;
    color: $primary;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    border-radius: 3px;
    margin-right: 15px;
}

#header {
    .ets_mm_megamenu_content {
        #accordion {
            left: -0.9375rem;

            > li:nth-child(even) {
                .mm_tab_li_content {
                    background-color: $gray-100;
                }
            }

            li.mm_tabs_li {
                .mm_tab_li_content {
                    padding: 1.0625rem 1.35rem;
                }

                .ets_mm_categories {
                    padding-left: 1rem;
                    > li {
                        width: 100%;
                        padding: 1.2rem 0.5rem;
                    }
                }

                &.sub-menu-open {
                    .mm_tab_li_content {
                        background-color: $blue-light;
                        .chevrondown {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-mobile {
    .header__logo,
    .header__logo--checkout {
        max-width: 50%;
    }
    .header__search {
        padding: 0;
        flex: auto;
        margin: 10px 15px 0 30px;
    }
    .header__logo--checkout {
        margin: 0 auto;
    }
}
@include media-breakpoint-desktop() {
    .header-top__col {
        width: 30%;
    }
    #menu-icon {
        display: none;
    }
    #header.header-minimize {

        .ets_mm_megamenu.layout_layout1 .ets_mm_megamenu_content .mm_menus_ul .mm_menus_li .mm_menu_content_title {
            padding: 5px 15px;
        }
    }
}
@include media-breakpoint-up(xl) {
    .header-top__col {
        width: 25%;
    }
}

.header-nav {
    overflow: hidden;
    max-height: 50px;
    transition: $transition_header;
}

#custom-text {
    border: none;
    background: none;
    color: $primary;
    font-weight: bold;
    line-height: 1.625rem;
    .card-body {
        padding: 0;
    }
    p {
        margin: 0;
        &:not(:first-child) {
            font-size: 12px;
        }
    }
}

.header-link-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    img {
        height: 1.3125rem;
        width: auto;
        margin-right: 0.625rem;
        transition: $transition_header;
        transition-property: height;
    }
    .header-title-icon {
        font-size: 1rem;
        line-height: 1;
        color: $primary;
    }
    &:hover {
        .header-title-icon {
            color: $tertiary;
        }
    }
}

.blockcart {
    .header-link-icon {
        position: relative;
        .header-svg-icon {
            position: relative;
            z-index: 1;
        }
        .blockcart__count {
            position: absolute;
            top: -0.125rem;
            left: 1.25rem;
            transform: translate(-50%, -50%);
            background-color: $tertiary;
            color: $primary;
            font-weight: bold;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
        }
    }
}

body #header .header-top .quotation_cart .header .quote-header-element.quote-text {
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 0;
}

#header {
    .header-contact-rich-cont {
        position: absolute;
        top: 100%;
        left: -15px;
        display: none;
        z-index: 10;
        padding-top: 1rem;
        .header-contact-rich {
            min-width: 380px;
            background-color: $tertiary;
            padding: 15px;
            .h4 {
                color: $white;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 24px;
            }
            .header-contact-block {
                background-color: $white;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                .icon {
                    width: 50px;
                    margin-bottom: 0;
                }
                .data {
                    font-size: 12px;
                    margin-left: 15px;
                    p {
                        margin-bottom: 0;
                        white-space: pre-line;
                    }
                    .header-contact-block-subtitle {
                        color: $primary;
                        font-weight: bold;
                        font-size: 18px;
                    }
                }
                &:hover {
                    .icon {
                        filter: brightness(0) saturate(100%) invert(49%) sepia(100%) saturate(796%) hue-rotate(13deg)
                            brightness(106%) contrast(98%);
                    }
                    .data {
                        .header-contact-block-subtitle {
                            color: $tertiary;
                        }
                    }
                }
            }
        }
    }
}
#contact-link {
    cursor: default;
}

@include media-breakpoint-up(sm) {
    #contact-link:hover .header-contact-rich-cont {
        display: block;
    }
}

#header .header-top .quotation_cart span.ajax_quote_quantity {
    background-color: $yellow-dark;
    color: #2f395e;
}

body #header .header-top ._desktop_quotecart .quotation_cart {
    .quotation-cart-link {
        &:hover {
            .header {
                .quote-header-element.quote-text {
                    color: $yellow-dark;
                }

                .quote-header-element > svg path {
                    fill: $yellow-dark;
                }

                .ajax_quote_quantity {
                }
            }
        }
    }

    .block_quote_collapse {
        display: none !important;
    }

    .header {
        padding: 0;

        .quote-header-element > svg {
            width: auto;
            height: 1.3125rem;
            margin-right: 0;
        }

        .ajax_quote_quantity {
            position: absolute;
            top: -0.125rem;
            left: 0.625rem;
            transform: translate(0, -50%);
            background-color: $yellow-dark;
            color: #2f395e;
            font-weight: 700;
            font-size: 1rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
        }
    }
}

@media (max-width: 991.98px) {
    body #header .header-top ._desktop_quotecart .quotation_cart .header .quote-header-element > svg {
        height: 30px;
    }

    body #header .header-top .quotation_cart .header .quote-header-element.quote-text {
        display: none;
    }

    #header .header-top ._desktop_quotecart {
        margin-right: 1rem !important;
    }
}

@include media-breakpoint-up(md) {
    .wrap-inner-menu {
        display: flex;
        align-items: center;
    }
}

@include media-breakpoint-down(xl) {
    #header {
        .header__search {
            order: 10;
            padding: 1rem 0 0;

            .search-widget {
                margin: 0 auto;
            }
        }
    }
}
@include media-breakpoint-down(md) {
    .header-contact-rich-cont {
        right: -15px;
        left: auto;
    }
    #header {
        #menu-icon {
            padding: 1rem;
            justify-content: flex-start;
            width: 5.375rem;

            i.fa-bars {
                width: 1.25rem;
                height: 1.3125rem;
                overflow: hidden;
                border-radius: 0;
                margin-right: .625rem;
                background: url('../img/burger.svg') center center no-repeat;
                &:before {
                    content: none;
                }
            }
        }
        .header__rightitem:not(:last-child) {
            margin-right: 1.25rem;
        }
        .header-link-icon img {
            height: 30px;
            margin-bottom: 0;
        }
        .header__logo .logo {
            height: 3.125rem;
            width: auto;
        }
        .search-widget__input-right {
            padding: 0.625rem;
            font-size: 1rem;
        }
        &.header-minimize .header-link-icon img {
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    main {
        #header {
            .header-nav {
                max-height: unset;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
                .header__container {
                    padding: 0;
                    .card {
                        font-size: 0.85rem;
                        line-height: 1.5;
                    }
                }
            }
            .header-top {
                position: relative;
                padding-top: 0.625rem;
                padding-bottom: 0.625rem;
            }
            .header__right {
                padding-left: 0;
                padding-right: 0;
                position: static;
                #contact-link {
                    position: static;
                    .header-contact-rich-cont {
                        left: 0;
                        top: 75px;
                        .header-contact-rich {
                            min-width: inherit;
                            width: 100vw;
                        }
                    }
                }
            }
            .header__rightitem:not(:last-child) {
                margin-right: 0.5rem;
            }
            .header__search {
                width: 100%;
                margin: 0;
            }

            #_desktop_quotecart, .user-info.header__rightitem {
                display: none;
                padding: 0;
                margin: 0;
            }

            .header-link-icon {
                &.show-contact-infos {
                    .header-contact-rich-cont {
                        display: block;
                    }
                }
            }

            .chevrondown {
                transition: all 0.3s ease;
            }

            .ets_mm_megamenu.layout_layout1 {
                .wrap-inner-menu {
                    height: 100vh;
                    background: $white;
                }
                .ets_mm_megamenu_content {
                    .mm_menus_ul {
                        height: 100vh;
                        overflow-y: auto;
                        overflow-x: visible;
                        .mobile-menu-header {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-around;
                            gap: 0.875rem;
                            padding: 0.875rem;
                            margin: 0;

                            .close-menu {
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 3.125rem;
                                height: 3.125rem;
                                background: $blue-dark;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                i {
                                    color: $white;
                                }
                            }

                            .header-title-icon, .quote-text {
                                display: block;
                                font-size: 0.875rem;
                                line-height: 1.25;
                                &:after {
                                    content: none;
                                }
                            }

                            .header-link-icon {
                                display: flex!important;
                                flex-direction: column;
                                gap: .5rem;
                                img, .header-svg-icon {
                                    margin: 0;
                                    height: 1.3125rem;
                                    width: auto;
                                }
                            }

                            ._desktop_quotecart {
                                text-align: center;
                                .header {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.5rem;
                                }
                                svg {
                                    margin: 0;
                                }
                                .quote-icon {
                                    margin: 0;
                                    height: 1.25rem;
                                    svg, img {
                                        height: 1.3125rem;
                                        width: auto;
                                        margin: 0;
                                    }
                                }
                                .ajax_quote_quantity {
                                    position: absolute;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    top: -.625rem;
                                    right: -0.3125rem;
                                    bottom: unset;
                                    width: 1.25rem;
                                    height: 1.25rem;
                                    &:after {
                                        content: none;
                                    }
                                }
                                .block_quote_collapse {
                                    display: none;
                                }
                            }

                            .user-info {
                                .header-link-icon {
                                    flex-direction: column;
                                    gap: .5rem;
                                    .header-svg-icon {
                                        margin: 0;
                                    }
                                }
                            }

                            .blockcart {
                                a.header-link-icon {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.5rem;
                                    align-items: center;
                                    justify-content: center;
                                }
                                .header-svg-icon {
                                    margin: 0
                                }
                                .blockcart__count {
                                    position: absolute;
                                    top: 0.125rem;
                                    bottom: unset;
                                    right: -0.9375rem;
                                    left: unset;
                                    z-index: 1;
                                    font-weight: 400;
                                }
                            }

                            #contact-link {

                            }

                        }
                        .mm_menus_li {
                            > a {
                                text-transform: initial;
                                font-weight: 600;
                            }

                            &.mm_menus_li_tab {
                                padding: 1.238rem .9375rem;
                                margin: 0;
                                width: 100%!important;
                                > a {
                                    padding: 0;
                                }
                            }

                            &.opened {
                                > a {
                                    &:after {
                                        content: "";
                                        width: calc(100% + 1.875rem);
                                        height: 3.8rem;
                                        display: block;
                                        position: absolute;
                                        background: #eecc8e;
                                        left: -0.9375rem;
                                        top: -1.237rem;
                                        z-index: -1;
                                    }
                                }
                                .mm_columns_ul_tab {
                                    margin-top: 1.1rem;
                                }
                            }

                            .arrow {
                                top: 0;
                            }
                        }
                    }

                    #accordion {
                        left: -0.9375rem;
                        width: calc(100% + 1.875rem)!important;

                        > li:nth-child(even) {
                            .mm_tab_li_content {
                                background-color: $gray-100;
                            }
                        }

                        li.mm_tabs_li {
                            .mm_tab_li_content {
                                padding: 1.0625rem 0.8575rem;
                                font-weight: 700;
                                &.opened {
                                    background-color: $blue-light;
                                    .chevrondown {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .ets_mm_categories {
                                > li {
                                    width: 100%;
                                    padding: 1.2rem 0.5rem;
                                }
                            }
                        }
                    }
                }
            }

            // OPTI GOOGLE
            .header-contact-rich-cont .header-contact-rich .header-contact-block .data,
            #custom-text p:not(:first-child) {
                font-size: 16px;
            }
        }
    }
}
