/* PRODUCT MINIATURE */
$pm-bg-color: null;
$pm-border-width: null;
$pm-border-color: null;
/* product title */
$pm-name-color:                $primary;
$pm-name-hover-color:          $tertiary;
$pm-name-font-size:            18px;
$pm-name-font-weight:          600;
$pm-name-font-family: null;
$pm-name-hover-border-width: 1px;
$pm-name-hover-border-color: transparent;

$pm-highlight-bg-color: #fff;
$pm-highlight-translateY: 0;