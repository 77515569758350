.account-links {
    @extend .align-items-stretch;
    > a {
        margin-bottom: $spacer;
    }
    .link-item {
        @extend .card;
        padding: $spacer;
        text-align: center;
        font-weight: 700;
        height: 100%;
        border: none;
        @include media-breakpoint-down(sm) {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            text-align: left;
        }
        i, svg {
            margin: 0 auto $spacer;
            font-size: $font-size-base * 3;
            @include media-breakpoint-down(sm) {
                font-size: 2rem;
                margin: 0!important;
                width: 1.875rem!important;
                height: 1.875rem!important;
            }
        }
        &:hover {
            color: $tertiary;
        }
    }
    #bt_sl-link,
    #author-blog-comment-link,
    #author-blog-info-link {
        display: none;
    }
}

.address {
    @extend .card;
    height: 100%;
}
.address-header {
    @extend .card-header;
    background-color: transparent;
}
.address-body {
    @extend .card-body;
}
.address-footer {
    @extend .card-footer;
    display: flex;
    justify-content: space-between;
}

.form--100 {
    min-width: 100%;
    width: 100%;
}

#my-account {
    .ss_icon_group {
        display: inline-block;
        i {
            height: auto;
        }
    }
    .page-footer--my-account {
        a {
            font-weight: bold;
            .add-logout {
                text-transform: initial;
            }
        }
    }
}

#identity {
    .page-content {
        form {
            max-width: 600px;
            padding: 30px;
            background-color: $white;
        }
    }
}

.login-warning {
    background: rgba($tertiary, 0.5);
    color: $primary;
    padding: 15px;
    font-weight: 600;
}

// DUAL BLOC LOGIN //

.smallform-cont,
.smallform-cont .smallform {
    justify-content: center;
    display: flex;
}

.smallform-cont {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 30px;
    > div {
        position: relative;
        &:nth-child(even) {
            &:before {
                content: "";
                position: absolute;
                left: -0.125rem;
                top: 0;
                width: 0.25rem;
                height: 100%;
                background: $tertiary;
            }
        }
    }

    .register-form {
        background: $primary;
        color: $white;
        .smallform-title {
            color: $white;
        }
        .btn-tertiary {
            font-weight: bold;
            text-transform: uppercase;
            &:hover {
                background-color: $secondary;
            }
        }
    }
}

.smallform {
    padding: 30px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 4px 2px 2px rgba($black, 0.05);
    .form-footer,
    .no-account {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
    }

    & > div {
        width: 100%;
    }

    .smallform-title,
    .surheader {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .smallform-title {
        text-transform: uppercase;
        font-weight: bold;
    }
    .surheader {
        font-size: 18px;
    }

    .smallform-body.show ~ .smallform-arrow {
        .material-icons {
            transform: rotate(180deg);
        }
    }
}

.smallform-body {
    padding-top: 40px;
}

.smallform .smallform-arrow {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 1rem;
    width: fit-content;
    .material-icons {
        font-size: 40px;
        transition: all 0.1s ease-in-out;
    }
}

// SOCIAL LOGIN //

.socialcont-sep {
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    margin-top: calc(25px + 0.5rem);
    margin-bottom: 0;
}
.socialcont #fbpsc.socialcont,
#sl_connector_customer-login #fbpsc.customer-login,
#sl_connector_customer-registration #fbpsc.customer-registration {
    background: none;
    border: none;
}
.socialcont {
    width: 100%;
}
.social-connect-btn {
    .h4 {
        font-weight: 600;
    }
}
#fbpsc {
    .btn-google .btn-title-connect {
        font-size: 0;
    }
    .btn-facebook .btn-title-connect,
    .btn-twitter .btn-title-connect,
    .btn-paypal .btn-title-connect,
    .btn-amazon .btn-title-connect {
        font-size: 0;
        font-weight: 400;
        &:after {
            font-size: 18px;
            display: inline-block;
            margin-top: -3px;
        }
    }
    .btn-connect.btn-social {
        padding: 5px 10px;
        transition: all 0.3s ease-in-out;
        width: auto;
        display: inline-flex;
        align-items: center;
        border: 2px solid transparent;
        &:hover {
            border-color: $tertiary;
        }
        > :first-child {
            position: initial;
            margin: 0;
        }
        .btn-title-connect {
            padding-left: 0;
            &:after {
                margin-top: 0;
                font-size: 16px;
            }
        }
    }
    .btn-social > :first-child {
        line-height: 34px;
    }
    .btn-facebook .btn-title-connect:after {
        content: "Facebook";
    }
    .btn-google .btn-title-connect:after {
        content: "Google";
        font-size: 16px;
    }
    .btn-twitter .btn-title-connect:after {
        content: "Twitter";
    }
    .btn-paypal .btn-title-connect:after {
        content: "Paypal";
    }
    .btn-amazon .btn-title-connect:after {
        content: "Amazon";
    }
}

.login-form {
    .smallform-head {
        @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
        }
    }
}


@media (min-width: 768px) {
    .smallform-cont {
        grid-template-columns: 1fr 1fr;
    }

    .smallform-arrow {
        display: none !important;
    }
    .smallform {
        padding-bottom: 24px;
    }

    .smallform-head {
        margin-bottom: 20px;
    }

    .smallform .smallform-body {
        padding-top: 0;
    }
}

// < 768px
@include media-breakpoint-down(sm) {
    .smallform-cont {
        padding: 15px;
        > div:nth-child(even) {
            &:before {
                left: 0;
                top: 0;
                height: 0.25rem;
                width: 100%;
            }
        }
    }
    .smallform-title.h2 {
        font-size: 24px;
    }
}
