#product {
    .col-lg-6>.pureproductpictogram img {
        max-width: 90px;
        max-height: 90px;
    }

    #content.page-content--product {
        position: sticky;
        top: 150px; // height of header when scrolled
    }

    .product-description-short {
        line-height: 162.5%;
    }

    .wrap-product-rental-action {
        gap: 0.625rem;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            gap: 1.25rem;
            > * {
                width: 100%;
            }
        }
    }

    .product-information {
        .product-security-social {
            padding: 0.3125rem 1.25rem;
            background: $white;
            border-radius: 2.5rem;
            border: 1px solid $green-dark;
            color: $green-dark;
            gap: 0.25rem;
            flex-wrap: wrap;
            line-height: 1.25;
        }
    }

    .rental-location, .rental-duration {
        padding: 0.3125rem 1.25rem;
    }

    .rental-location {
        @include media-breakpoint-down(sm) {
            margin: 2.5rem 0 0;
        }
    }

    .product-price {
        display: flex;
        align-items: center;
        gap: 0.625rem;

        .current-price {
            display: flex;
            align-items: center;
            gap: 0.625rem;
        }

        .price {
            font-size: 1.75rem;
            line-height: 1;
            color: $primary;
        }

        .regular-price {
            font-size: 0.875rem;
            font-weight: normal;
        }

        .product-discount {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            gap: 0.625rem;
        }

        .tax-shipping-delivery-label {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            color: $primary;
            align-self: end;
        }

        .NETREVIEWS_PRODUCT_STARS {
            margin-left: 15px;

            .netreviews_review_rate_and_stars {
                .nr-icon {
                    font-size: 25px;
                    max-width: 25px;
                }

                >div:nth-child(2) {
                    margin-top: -25px;
                }
            }
        }

        div[itemprop="aggregateRating"],
        span[itemprop="review"] {
            font-size: 0;
            opacity: 0;
            visibility: hidden;
            width: 0;
            height: 0;
            position: absolute;
        }

        .netreviews_bg_stars_big,
        .netreviews_review_rate_and_stars,
        .netreviews_bg_stars {
            .nr-icon {
                font-size: 0; // in case price get in for some reasons I don't understand...
            }
        }

        #slide {
            display: none;
        }

        .netreviews_font_stars div .nr-star:before {
            font-size: 25px;
        }

        .netreviews_stars_light {
            margin-left: 15px;
        }

        .netreviews_stars_light,
        .netreviews_review_rate_and_stars {
            height: 25px;
            width: 125px;
        }
    }

    .product-minimal-quantity {
        margin-bottom: 0;
    }

    .promo-code-banner {
        background-color: rgba($tertiary, 0.35);
        padding: 5px 15px;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: $primary;
        line-height: 1.3;

        p {
            margin: 0;
        }
    }

    .delivery-information-label {
        font-weight: bold;
        color: $primary;
    }

    .delivery-information {
        font-weight: 600;
        font-size: 14px;
    }

    .wrap-quantity-add {
        gap: 1.25rem;
        margin-bottom: 1.25rem;
    }

    .wrap-devis-print {
        gap: 1.25rem;
    }

    .product-quantity {
        margin-top: 30px;
        flex-wrap: nowrap;

        .qty > .input-group {
            display: flex;
            align-items: center;
            #quantity_wanted {
                width: 3.125rem;
                height: 3.125rem;
                font-weight: 400;
            }
            .input-group-btn {
                width: 3.125rem;
                height: 3.125rem;
                .btn {
                    font-weight: bold;
                    padding: 0.5rem 1rem;
                    width: 100%;

                    &:hover {
                        background-color: $tertiary;
                        color: $black;
                    }
                }
            }


            .input-group-prepend {
                grid-area: minus;
                margin: 0;
            }

            .input-group-append {
                grid-area: plus;
                margin: 0;
            }
        }

        .product-cta-buttons {
            &.no_add-to-cart {
                padding-left: 15px;
            }
        }

        .add-to-cart,
        .btn-print,
        .btn-quote {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.125rem;
        }

        .btn-print {
            text-transform: initial;
            text-decoration: underline;
            font-weight: bold;
            font-size: 1rem;
        }

        .add {
            grid-area: add;
            height: 100%;
            padding-right: 0;

            .add-to-cart {
                letter-spacing: 1px;
            }
        }

        .btn-print {
            grid-area: print;
        }

        .btn-quote {
        }
    }

    #product-availability,
    #product-instock {
        margin: 15px 0;
        background: rgba($secondary, 0.2);
        padding: 8px 15px;
        font-weight: bold;
        color: $primary;
        display: block;
        text-align: center;
    }

    #product-instock {
        color: $red;
    }

    .products-imagescover {
        .slick-list {
            padding-bottom: 0;
        }
    }

    .js-mailalert {
        .mailalert_row {
            display: flex;
            align-items: stretch;
        }

        .form-control {
            border: none;
        }

        .btn {
            // white-space: nowrap;
            border: none;
            margin: 0 auto;
        }
    }

    .product-additional-info {
        #gdpr_consent {
            font-size: 12px;
            text-align: center;

            .custom-control-label {
                margin-top: 5px;

                &:before,
                &:after {
                    top: calc(0.25rem - 3px);
                }
            }
        }
    }

    .wrap-product-reassurance {
        li {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            padding-left: 1.625rem;
            position: relative;
            font-weight: 600;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.125rem;
                width: 1.25rem;
                height: 1.25rem;
                background: url('../img/check.svg') center center no-repeat;

            }
        }
    }

    #multiAccessoriesTab {
        padding: 1.25rem;
        background: $white;

        .accessories_table_price {
            display: none;
        }

        #group_accessories {
            padding: 0;
            margin: 0;
            background: transparent;

            h3 {
                text-transform: uppercase;
                color: $blue-dark;
                font-size: 1.125rem;
                margin: 0;
            }

            .option-row {
                .content_group {
                    .accessorygroup {
                        tr {
                            td {
                                padding: 1.125rem 0;
                                border-bottom: 1px solid $gray-600;
                            }
                            //&:nth-child(n+3) {
                            //    display: none;
                            //}
                            &:last-child {
                                td {
                                    border-bottom: none;
                                }
                            }
                        }
                        .select_box {
                            width: 1.25rem;
                        }
                        .checkbox_radio_image {
                            width: 95px;
                            padding: 0 1.25rem;
                        }
                        .block_accessory_name {
                            margin-bottom: 1.25rem;
                        }
                        .accessory_price {
                            font-weight: 700;
                            margin-right: 1.25rem;
                        }
                        .product-combination {
                            margin-bottom: 1.25rem;
                        }
                        .ma_block_qty {
                            display: inline-flex;
                            align-items: center;
                            border: 1px solid $gray-300;
                            button {
                                &.btn-touchspin {
                                    width: 50px;
                                    height: 50px;
                                    border: none;
                                }
                            }
                            .custom_quantity {
                                border: none;
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }

    .product-tabs {
        margin-top: 5rem;

        @include media-breakpoint-down(sm) {
            .nav-tabs {
                 .nav-item {
                     .nav-link {
                         padding: .5rem 0;
                         transition: all .3s ease;
                         &.active {
                             padding: .5rem 1rem;
                             background: $yellow-dark;
                             text-decoration: none;
                             border-color: transparent;
                         }
                     }
                 }
             }
        }

        .tab-content {
            background: $white;
            padding: 2.5rem;
            @include media-breakpoint-down(sm) {
                padding: 1rem;
                margin-top: 1.125rem;
            }
        }

        .tab-pane {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .tab-videos {
                padding: 15px 0;
            }

            img {
                max-width: 100%;
            }
        }

        .custom-delivery-options {
            display: grid;
            grid-template-rows: repeat(3, 1fr);
            text-align: center;

            >* {
                padding: 5px;
                display: grid;
                grid-template-columns: 0.5fr 50px 1fr;
                justify-content: center;
                align-items: center;
            }

            img {
                margin: auto;
            }

            .countries {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img:not(:last-child) {
                    margin-bottom: 5px;
                }
            }
        }

        .custom-return-grid {
            display: grid;
            grid-template-columns: 0.75fr 1fr;
            text-align: center;

            .h4 {
                text-transform: uppercase;
                color: $secondary;
                font-size: 16px;
                font-weight: bold;
            }

            >* {
                border: 1px solid $bg-dark;
                margin: -1px 0 0 -1px;
                padding: 5px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .custom-return-btn {
            text-transform: uppercase;
            font-weight: 600;
            margin-top: 15px;
        }

        .ets-ept-prod-tabs {
            margin: 0;
        }

        iframe {
            max-width: 100%;
            width: 100%;
            height: 350px;
        }

        .slick-track {
            max-width: initial;
        }

        .custom-product-videos-thumbnails {
            .slick-list {
                margin: 0 54px;
                padding: 0 !important;

                .slick-slide {
                    padding: 5px;
                    cursor: pointer;
                    position: relative;

                    &:after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        height: 0;
                        width: 0;
                        border-top: 30px solid transparent;
                        border-bottom: 30px solid transparent;
                        border-left: 60px solid $white;
                        opacity: 0;
                        transition: opacity 0.2s ease-in-out;
                    }

                    &:hover:after {
                        opacity: 0.75;
                    }
                }
            }

            .slick-arrow {
                height: auto;
                top: 50%;
                transform: translateY(-50%);

                &:hover {
                    filter: invert(56%) sepia(99%) saturate(731%) hue-rotate(6deg) brightness(98%) contrast(98%);
                }
            }

            .custom-video-thumbnail {
                max-height: 125px;
                padding: 5px;
                width: auto !important; // for Safari
            }
        }

        .product-attachments {
            padding: 15px;

            .attachment {
                >a {
                    display: flex;
                    align-items: center;
                    padding: 15px;

                    p,
                    span {
                        margin-bottom: 0;
                        color: $black;
                        transition: transform 0.2s ease-in-out;
                    }

                    .attachment_txt {
                        margin-left: 15px;

                        .attachment_name {
                            font-weight: bold;
                        }

                        .attachment_desc {
                            font-size: 14px;
                        }
                    }

                    .attachment_size {
                        margin-left: auto;
                        font-size: 12px;
                    }

                    .attachment_img img {
                        width: 54px;
                    }

                    &:hover {
                        p {
                            color: $tertiary;
                            transform: translateX(5px);
                        }
                    }
                }
            }
        }

        #product-details {
            label {
                font-weight: bold;
            }
        }
    }

    #group_accessories h4 {
        display: none;
    }

    #netreviews_reviews_tab {

        .netreviews_filtering_section,
        .netreviews_filtering_section2 {
            padding: 0;
            #netreviews_reviews_filter {
                background-color: transparent;
                border: 1px solid;
                border-radius: 0;
            }
        }

        .netreviews_tpl_v2 {
            #netreviews_reviews_section {
                padding-top: 15px;
            }

            #netreviews_table_tab {
                border-spacing: initial;
            }
        }
    }

    // BLOG
    .ybc-blog-related-posts {
        padding: 0;
        margin: 0;
        box-shadow: none;
        background: none;
        border: none;

        ul.ybc-blog-related-posts-list {
            margin: 0;
            width: 100%;

            .ybc-blog-related-posts-list-li {
                margin-top: 30px;
                padding-bottom: 30px;

                .ybc_item_img img {
                    max-height: 300px;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        .ybc_title_block,
        .ybc-blog-sidear-post-meta,
        .ybc-blog-latest-toolbar,
        .blog_description {
            padding: 0 15px;
        }

        .ybc_title_block {
            color: $primary;
            font-size: 20px;
            margin-top: 15px;

            &:hover {
                color: $tertiary;
            }
        }

        .blog_description {
            color: inherit;
            font-size: 15px;
            margin-bottom: 15px;
            margin-top: 15px;
        }

        .btn {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 20px;
            padding: 2px 15px;
            margin: 0 15px;
        }

        .owl-stage,
        .owl-item {
            width: auto !important;
        }

        .owl-controls {
            display: none;
        }
    }

    .wpcf7 form {
        display: flex;
        flex-wrap: wrap;
        position: relative;

        h3 {
            color: $primary;
            font-weight: bold;
            font-size: 24px;
            margin-top: 15px;
        }

        label {
            width: 100%;
            margin-bottom: 15px;

            &.input_half {
                width: 50%;
            }

            &.half_left {
                padding-right: 15px;
            }

            &.half_right {
                padding-left: 15px;
            }
        }

        input[type="submit"] {
            display: none;
        }

        ~.clearfix {
            display: none;
        }

        .ajax-loader {
            position: absolute;
            bottom: 8px;
            left: 50%;
            transform: translateX(-50%);
        }

        .wpcf7-response-output {
            margin: 0 0 15px 0;
            width: 100%;
            text-align: center;
        }
    }

    #roja45quotationspro_buttons_block {
        display: none;
    }

    .devisonly_buttons {}
}

body#module-roja45quotationspro-QuotationsProFront {
    #main {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .quote_title {
        color: #2f395e;
        font-size: 2.625rem;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
            font-size: 24px;
        }
    }

    .quote_navigation {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        &:after {
            content: none;
        }
    }

    .quotationspro_request.notes {
        background-color: transparent;

        .customer-copy-checkbox .field-label {
            color: #2f395e;
        }
    }

    @media (max-width: 767.98px) {
        .quote_title {
            font-size: 24px;
        }
    }
}

body#module-roja45quotationspro-QuotationsProFront-GetCustomerQuotes,
body#module-roja45quotationspro-QuotationsProFront-GetCustomerQuoteHistory {
    .page-header {
        h4 {
            color: #2f395e;
            font-size: 2.625rem;
            text-transform: uppercase;
        }
    }

    @media (max-width: 767.98px) {
        .page-header h4 {
            font-size: 24px;
        }
    }
}

#roja45_customer_quotes {
    overflow: auto;

    .flex-table {
        min-width: 928px;

        .flex-row.header:first-of-type {
            background-color: white;
            font-weight: 700;
            text-transform: none;
            color: #2f395e;
        }

        .flex-row:nth-of-type(even) {
            background-color: #f3f3f3;
        }

        .flex-row .flex-cell {
            padding: 10px;
        }
    }

    .flex-table .flex-row .flex-cell {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.row>#plabel_banner_content {
    width: 100%;
    margin: 0 15px;
}

@media (min-width: 769px) {
    #request-summary-products {
        .flex-table .flex-row .flex-cell {
            &.quote-product-image {
                width: 30%;
                flex: initial;

                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

#roja45_customer_quotes {
    .fixed-cell {
        min-width: 145px;
        max-width: 145px;
    }
}



@media (max-width: 1399px) {
    #product {
        .product-quantity {
            .product-cta-buttons {
                grid-template-areas: "add add" "print quote";
            }

            .add {
                padding-left: 0;
            }

            .qty {
                margin-right: 10px;
            }
        }

        .js-mailalert {
            .mailalert_row {
                flex-direction: column;

                .btn {
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: 1299px) {
    #product {
        .product-quantity {
            flex-wrap: wrap;

            .qty {
                margin-bottom: 8px;

                >.input-group {
                    grid-template-areas: "minus input plus";
                    grid-template-columns: 0.75fr 1fr 0.75fr;

                    input {
                        max-width: inherit;
                    }
                }
            }
        }
    }
}

// < 992px
@include media-breakpoint-down(md) {
    #product {
        #content {
            padding-top: 30px;
        }

        .pureproductpictogram {
            margin-top: 15px;
        }

        .product-quantity {
            .quantity__label {
                display: block;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            .qty>.input-group {
                display: flex;

                #quantity_wanted {
                    height: 40px;
                }

                input {
                    max-width: 60px;
                }
            }
        }

        .product-tabs {
            ul.tab-list {
                margin-bottom: 10px;
            }
        }

        .zopim {
            bottom: 117px !important;
        }
    }
}

// < 768px
@include media-breakpoint-down(sm) {
    #product {
        h1 {
            font-size: 1.75rem;
            margin-top: 1.25rem;
            line-height: 1.25
        }

        .product-description-short {
            font-size: 0.875rem;
        }

        .product-price {
            .product-discount {
                .regular-price {
                    font-size: 0.875rem;
                }

                .discount {
                    font-size: 12px;
                    &-product {
                        font-size: 0.75rem
                    }
                }
            }

            .price {
                font-size: 1.375rem;
            }
        }

        .promo-code-banner {
            padding: 5px;
            font-size: 15px;
        }

        .product-quantity {
            .product-cta-buttons {
                .wrap-quantity-add {
                    flex-direction: column;
                    align-items: flex-start !important;
                    margin-top: 0;

                    .qty {
                        margin: 0;
                        .input-group {
                            #quantity_wanted {
                                height: 3.125rem;
                            }
                        }
                    }

                    .add {
                        width: 100%;
                    }
                }

                .wrap-devis-print {
                    flex-direction: column;
                    align-items: flex-start !important;

                    .btn-print {
                        padding: 0;
                        width: auto;
                        text-decoration: underline;
                    }
                }
            }

            .add-to-cart,
            .btn-print,
            .btn-quote {
                font-size: 1rem;
                width: 100%;
            }
        }

        .product-tabs {
            padding: 0;
            margin-top: 1rem;

            .tab-list {
                padding: 0;
            }

            .tab-header {
                font-size: 16px;

                &:after {
                    width: 26px;
                    height: 26px;
                }
            }

            .nav-tabs {
                flex-direction: column;
            }
        }

        .zopim {
            bottom: 91px !important;
        }

        .link_show_please_call_me {
            bottom: 125px;
        }

        .bqzerq {
            // axeptio
            bottom: 80px;
        }

        // OPTI GOOGLE
        .product-description-short,
        .product-price .product-discount .regular-price,
        .product-price .product-discount .discount,
        .promo-code-banner,
        .netreviews_helpful_block,
        .netreviews_helpful_block a,
        .netreviews_helpful_block a span,
        .netreviews_customer_review,
        .netreviews_customer_name,
        .netreviews_customer_name span,
        .quantity__label,
        .oneyCta_button {
            font-size: 16px;
        }
    }
}