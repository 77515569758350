@include media-breakpoint-up(md) {
    .slick-list {
        padding-bottom: 6.25rem;
    }
}

.slick-arrow {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 50px;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    background-position: center center;
    background-repeat: no-repeat;
    border: none;
    font-size: 0;
    color: transparent;
    z-index: 9;
}

.slick-prev {
    background-image: url(../img/arrowleft.svg);
    transform: translateX(calc(-50% - 35px));
}

.slick-next{
    background-image: url(../img/arrowright.svg);
    transform: translateX(calc(-50% + 35px));
}

.slick-slide figure{
    margin: 0;
}

.slick__arrow-outside {
    .slick-prev {
        transform:translateX(-50px);
    }
    .slick-next{
        transform:translateX(50px);
    }
}

.slick__arrow-large{
    .slick-arrow i{
        font-size: 3*$font-size-base;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
        background: transparent;
        color: #fff;
    }
}

.slick-track {
    gap: 0.625rem;
}

.slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    margin-top: 1rem;
    width: 100%;

    li {
        max-width: unset!important;
        flex: initial!important;

        button {
            border-radius: 1rem;
            border: 0.0015rem solid $gray-600;
            width: 1rem;
            height: 1rem;
            font-size: 0;
            background: $white;
            box-shadow: unset;
            -webkit-appearance: unset;
        }

        &.slick-active {
            button {
                background-color: $blue-dark;
                border-color: $white;
            }
        }
    }

}