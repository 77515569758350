// CONTACT
.contact-rich {
    box-shadow: 4px 2px 2px rgba($black, 0.05);
    background: $white;
    padding: 30px 15px;
    border-radius: 5px;
    h4 {
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }
    .block {
        display: flex;
        .icon {
            margin-right: 5px;
            color: $primary;
        }
    }
}
#content {
    .contact-form {
        box-shadow: 4px 2px 2px rgba($black, 0.05);
        background: $white;
        padding: 30px 15px;
        border-radius: 5px;
        form {
            width: auto;
            max-width: 600px;
        }
    }
}

// 404
#pagenotfound {
    #main {
        position: relative;
        margin-top: 4rem;
        &:before {
            content: "404";
            font-size: 200px;
            line-height: 180px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-80%) translateX(-50%);
            color: rgba($primary, 0.1);
        }
    }
    .page-header--pagenotfound {
        border: none;
        background: none;
    }
    h1 {
        font-size: 32px;
        text-transform: none;
        text-align: center;
        margin-bottom: 60px;
        &:after {
            display: none;
        }
    }
    .page-not-found {
        text-align: center;
    }
}

// ETS CONTACT FORM 7
.wpcf7-form-control.wpcf7-submit {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    height: 100%;
    border: none;
    background-color: $primary;
    color: $white;
    transition: background-color 300ms ease-in-out;
    &:hover {
        background-color: $tertiary;
    }
}

// < 992px
@include media-breakpoint-down(md) {
    // CONTACT
    #content {
        .contact-form {
            margin-top: 30px;
            a:hover {
                color: $tertiary;
            }
        }
    }
}
