// HOME
.block.page_home {
    padding: 0;
    background: none;
    .title_blog {
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 3rem;
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 1px;
    }
    .block_content {
        padding-top: 6.6rem;

        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }

        ul:not(.slick-dots) {
            li {
                .ybc_item {
                    height: 100%;
                    padding: 5rem 1.25rem 1.25rem;
                    background: $white;
                    border-top: 0.1875rem solid transparent;
                    transition: all .3s ease ;
                }

                .ybc_item_img {
                    position: absolute;
                    top: 0;
                    left: 3.5rem;
                    width: calc(100% - 7rem);
                    transform: translateY(-65%);
                    transition: all .3s ease;
                    img {
                        height: 10rem;
                        width: 100%;
                        object-fit: cover;;
                    }
                }
                .ybc-blog-latest-post-content,
                .ybc-blog-popular-content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    height: 100%;
                    background-color: $white;

                    .ybc_title_block {
                        display: block;
                        max-height: 3.8rem;
                        font-size: 1.25rem;
                        font-weight: 700;
                        color: $blue-dark;
                        text-transform: uppercase;
                        line-height: 1.25;
                        margin-top: 0;
                        margin-bottom: 1.0625rem;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;

                        &:hover {
                            color: $yellow-dark;
                        }

                        @include  media-breakpoint-down(sm) {
                            font-size: 1rem;
                        }
                    }
                    .blog_description {
                        line-height: 20px;
                        color: $black;
                        --desc-lines: 2!important;
                        font-size: 1rem;
                        p {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: var(--desc-lines);
                            -webkit-box-orient: vertical;
                            margin-bottom: 1.0625rem;
                            line-height: 162.5%;
                            font-size: 1rem;
                            color: initial;

                            @include media-breakpoint-down(sm) {
                                font-size: 0.875rem;
                            }
                        }
                    }
                    .ybc-blog-sidear-post-meta,
                    .ybc-blog-latest-toolbar {
                        display: none;
                    }
                }

                &:hover {
                    .ybc_item {
                        border-top: 0.1875rem solid $yellow-dark;
                        .ybc_item_img {
                            transform: translateY(-70%);
                        }
                    }
                }
            }
        }
    }
}

.ybc_block_latest {
    .ybc_item {
        .read_more {
            text-decoration: underline;
            font-weight: 700;
        }
    }
}

#ybc_blog_page {
    .blog_list {
        .blog_title {
            text-align: center;
            font-weight: bold;
            font-size: 32px;
            margin-bottom: 60px !important;
            color: $primary;
        }
        flex-direction: column;
        > * {
            max-width: 100%;
            flex: 0 0 100%;
        }
        .blog-category {
            float: none;
            background: none;
            margin-bottom: 0;
        }
        .read_more {
            display: inline-block;
            font-weight: 600;
            margin-top: auto;
            text-decoration: underline;
        }
        #left-column {
            .blog_desc {
                text-align: center;
                font-size: 19px;
                max-width: 60%;
                margin: auto auto 45px;
                color: rgba($black, 0.75);
                line-height: 1.5;
            }
            .ybc_blog_sidebar {
                display: flex;
                flex-direction: column;
                .ybc_block_categories {
                    order: 1;
                    box-shadow: none;
                    background: none;
                    display: flex;
                    align-items: baseline;
                    padding: 0;
                    .title_blog {
                        margin-top: 0;
                        margin-right: 15px;
                        font-weight: bold;
                        font-size: 15px;
                        white-space: nowrap;
                    }
                    ul.tree {
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0;
                        li {
                            padding: 0;
                            margin: 2px 8px;
                            &:last-child {
                                margin-right: 0;
                            }
                            a {
                                background: $primary;
                                color: $white;
                                text-transform: uppercase;
                                text-align: center;
                                padding: 6px 10px;
                                font-weight: 600;
                                &.active {
                                    background: $primary;
                                }
                                &:before {
                                    content: none;
                                }
                                &:hover {
                                    background: $tertiary;
                                }
                            }
                        }
                    }
                }
                .ybc_block_latest {
                    order: 2;
                }
                .ybc_block_featured {
                    order: 3;
                }
                .ybc_block_search {
                    padding: 45px 30px;
                    box-shadow: none;
                    form {
                        max-width: 750px;
                        margin: auto;
                        .search,
                        .button {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 50px;
                            width: 50px;
                            padding: 0;
                        }
                        .search {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: $primary;
                            color: $white;
                            pointer-events: none;
                            font-size: 28px;
                        }
                        .button:hover + .search {
                            background: $tertiary;
                            color: $primary;
                        }
                    }
                }
                .ybc_block_default {
                    margin: 30px 0;
                    box-shadow: none;
                    background: none;
                    padding: 0;
                    .title_blog {
                        display: none;
                    }
                    .block_content {
                        background: $white;
                        padding: 45px;
                        margin: 0;
                    }
                    .ybc_item {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        .ybc_item_img + div {
                            // content
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                        }
                    }
                    .ybc_title_block {
                        text-transform: uppercase;
                        color: $primary;
                    }
                    .blog_description {
                        line-height: 1.4;
                        color: $black;
                        --desc-lines: 4;
                        height: 60px;
                        p {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: var(--desc-lines);
                            -webkit-box-orient: vertical;
                            margin-bottom: 30px;
                        }
                    }
                }
            }
            .block {
                ul:not(.slick-dots) {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 1rem 0;
                }
            }
        }
        #content-wrapper {
            margin-top: 60px;
            .ybc-blog-wrapper {
                margin: 0;
            }
        }
        &.blog_category {
            #left-column {
                .ybc_block_default {
                    display: none;
                }
            }
            #content-wrapper {
                margin-top: 30px;
                .ybc-blog-wrapper {
                    padding: 45px;
                    margin: 0;
                }
                .ybc_title_block {
                    color: $primary;
                    font-size: 24px;
                    &:hover {
                        color: $tertiary;
                    }
                }
                .post-wrapper {
                    display: flex;
                }
                .ybc-blog-wrapper-content-main {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
                .read_more {
                    margin-top: 15px;
                }
            }
        }
        &.blog_single_post {
            .ybc-blog-wrapper-content {
                h1 {
                    font-family: "Open Sans";
                }
                .blog_description {
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        line-height: 1.25;
                    }
                    h2 {
                        margin: 30px 0;
                        font-weight: bold;
                    }
                    h3 {
                        margin: 15px 0;
                        font-weight: 600;
                    }
                }
            }
            .content_table {
                padding: 30px;
                background: $wrapper-bg;
                .content_table_title {
                    text-transform: uppercase;
                    font-weight: bold;
                    color: $primary;
                    font-size: 20px;
                }
                .content_table_content {
                    margin-bottom: 0;
                    li {
                        font-size: 16px;
                        color: $primary;
                        margin-bottom: 5px;
                        &::marker {
                            font-weight: bold;
                            font-size: 20px;
                            margin-right: 5px;
                        }
                    }
                }
            }
            .anchor {
                display: block;
                position: relative;
                top: -160px;
                visibility: hidden;
                opacity: 0;
                margin: 0;
            }
            #left-column {
                order: 1;
                .ybc_block_latest,
                .ybc_block_search,
                .ybc_block_categories {
                    display: none;
                }
                .ybc_blog_sidebar .ybc_block_default {
                    margin-top: 0;
                    padding-top: 30px;
                    background-color: $white;
                    .title_blog {
                        display: block;
                    }
                }
            }
            #content-wrapper {
                .ybc-blog-wrapper-detail {
                    background: $white;
                    box-shadow: none;
                    margin-bottom: 0;
                    padding: 45px;
                }
                .page-heading {
                    font-weight: bold;
                    font-size: 28px;
                    margin: 30px 0 15px 0;
                    .title_cat {
                        float: none;
                    }
                }
                .post-details .ybc-blog-latest-toolbar {
                    font-size: 16px;
                }
                .ybc-blog-like-span::before {
                    float: none;
                }
                .polls-title {
                    > span {
                        font-weight: 600;
                    }
                    label {
                        padding: 8px 18px;
                        &[for="polls_post_1"] {
                            // oui
                            background: $secondary;
                            &:hover {
                                background: $tertiary;
                            }
                            &.checked + label {
                                opacity: 0.6;
                            }
                        }
                        &[for="polls_post_0"] {
                            // non
                            background: $primary;
                            &:hover {
                                background: $tertiary;
                            }
                        }
                    }
                }
                .ybc-blog-related-products {
                    .blog_title {
                        margin: 30px 0 !important;
                        font-size: 24px;
                    }
                }
            }
        }
        #content-wrapper {
            .ybc-blog-wrapper-comment {
                .ybc_comment_form_blog {
                    background: $wrapper-bg;
                    border: none;
                    .blog_title {
                        text-align: left;
                        font-family: "Open Sans";
                        color: rgba($black, 0.9);
                        font-size: 20px;
                        margin: 0 !important;
                        border: none;
                        padding: 45px;
                    }
                    .ybc-blog-form-comment {
                        border-top: 2px solid $white;
                        padding: 45px;
                        form {
                            label {
                                color: rgba($black, 0.9);
                                font-size: 18px;
                                margin-bottom: 15px;
                            }
                            input:not(#bc-capcha) {
                                height: 50px;
                                font-size: 20px;
                            }
                            .blog-rate-post {
                                align-items: center;
                                margin-bottom: 15px;
                                label {
                                    margin-bottom: 0;
                                }
                                .blog_rating_box {
                                    margin-left: 10px;
                                    .star::after {
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ybc_blog-comments-list {
                border: none;
                .blog-comment-line {
                    padding-bottom: 30px;
                    &:not(:last-child) {
                        margin-bottom: 30px;
                        border-bottom: 2px solid $wrapper-bg;
                    }
                    .comment-subject {
                        color: $black;
                        font-size: 20px;
                        text-transform: uppercase;
                        margin-bottom: 0;
                    }
                    .comment-by,
                    .comment-time,
                    .comment-rating,
                    .ybc-block-report-reply-edit-delete {
                        font-size: 18px;
                        margin: 15px 0;
                    }
                    .comment-by {
                        b {
                            margin-left: 4px;
                        }
                    }
                    .comment-time,
                    .comment-rating {
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                    .comment-rating {
                        > span {
                            font-weight: bold;
                        }
                    }
                    .comment-content {
                        margin-bottom: 0;
                    }
                }
            }
            .ybc-blog-related-posts {
                border: none;
                .ybc-blog-related-posts-list-li {
                    max-width: 100%;
                    flex: none;
                }
            }
        }
    }
    .blog-paggination {
        .links {
            > b {
                background: $primary none repeat scroll 0 0;
                border-color: $primary;
            }
            a:hover {
                background: $tertiary;
                color: $white;
            }
        }
    }
}

// < 992px
@include media-breakpoint-down(md) {
    // HOME
    .block.page_home {
        .block_content {
            ul:not(.slick-dots) {
                li {
                    max-width: 50%;
                    flex: 0 0 50%;
                    &:first-child {
                        max-width: 100%;
                        flex: 0 0 100%;
                    }
                    &:not(:first-child) .ybc_item_img {
                        img {
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    #ybc_blog_page {
        .blog_list {
            &.blog_category {
                #content-wrapper {
                    .ybc-blog-list {
                        > li {
                            width: 50%;
                        }
                    }
                    .ybc-blog-wrapper {
                        padding: 30px;
                    }
                    .post-wrapper {
                        flex-direction: column;
                        .ybc_item_img {
                            width: 100%;
                        }
                        .ybc-blog-wrapper-content {
                            width: 100%;
                            padding: 15px;
                        }
                        .blog_description {
                            margin-top: 15px;
                        }
                        .read_more {
                            margin-top: 15px;
                        }
                    }
                }
            }
            #left-column {
                .block {
                    ul:not(.slick-dots) {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }
}

// < 768px
@include media-breakpoint-down(sm) {
    // HOME
    .block.page_home {
        .title_blog {
            font-size: 24px;
        }

        .block_content {
            ul:not(.slick-dots) {
                li {
                    max-width: 100%;
                    flex: 0 0 100%;
                    .ybc-blog-latest-post-content,
                    .ybc-blog-popular-content {

                    }
                }
            }

            ul.slick-initialized {
                .slick-slide {
                    padding-top: 6.875rem;
                }
                .slick-dots {
                    margin-top: 1.25rem
                }
            }
        }
    }
    #ybc_blog_page {
        .blog_list {
            .blog_title {
                font-size: 24px;
            }
            #left-column {
                .block {
                    ul:not(.slick-dots) {
                        grid-template-columns: 1fr;
                    }
                }
                .ybc_blog_sidebar {
                    .ybc_block_default {
                        .block_content {
                            padding: 15px;
                            li {
                                padding: 0;
                                margin-bottom: 30px;
                            }
                        }
                    }
                    .ybc_block_categories {
                        flex-direction: column;
                        ul.tree li {
                            margin-top: 10px;
                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                    .ybc_block_search {
                        padding: 30px 15px;
                    }
                }
                .blog_desc {
                    max-width: 100%;
                    font-size: 16px;
                }
            }
            &.blog_category {
                #content-wrapper {
                    .ybc_title_block {
                        font-size: 16px;
                    }
                    .ybc-blog-list {
                        > li {
                            width: 100%;
                        }
                    }
                    .ybc-blog-wrapper {
                        padding: 15px;
                        .ybc-blog-latest-toolbar {
                            .ybc-blog-like-span,
                            .blog-extra-item.be-rating-block {
                                width: auto;
                            }
                        }
                    }
                    .post-wrapper {
                        .ybc-blog-wrapper-content {
                            padding: 0;
                        }
                    }
                }
            }
            &.blog_single_post {
                .content_table {
                    padding: 30px 15px;
                }
                #content-wrapper {
                    .ybc-blog-wrapper-detail {
                        padding: 15px;
                    }
                    .ybc-blog-wrapper-comment {
                        .ybc_comment_form_blog {
                            .blog_title {
                                padding: 30px 15px;
                                font-size: 18px;
                            }
                            .ybc-blog-form-comment {
                                padding: 30px 15px;
                                form {
                                    .blog-submit input:not(#bc-capcha) {
                                        height: auto;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                    .ybc_blog-comments-list {
                        .blog-comment-line {
                            .comment-by,
                            .comment-rating,
                            .comment-time,
                            .ybc-block-report-reply-edit-delete {
                                margin: 2px 5px;
                            }
                            .comment-by {
                                margin-left: 0;
                            }
                            .comment-content {
                                padding-top: 15px;
                            }
                        }
                    }
                    .page-heading .title_cat {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
