#header {
    .ybc-menu-toggle {
        display: none;
    }
    // .ets_mm_megamenu.layout_layout1 {
    .mobile-menu-header {
        display: none;
    }
    .ets_mm_megamenu_content {
        border: none;
    }

    .ets_mm_megamenu {
        &.layout_layout1 {
            .ets_mm_megamenu_content {
                .mm_menus_ul {
                    .mm_menus_li {
                        margin-right: 1.25rem;
                        .plus,
                        .minus {
                            font-size: 22px;
                            font-weight: bold;
                            color: $primary;
                            vertical-align: middle;
                            margin-right: 0.625rem;
                            height: 1.2em;
                            width: 1.2em;
                            text-align: center;
                            line-height: 1.2;
                            border-radius: 50%;
                            &:hover {
                                color: $tertiary;
                            }
                        }

                        &.mm_menus_li_tab {
                            padding: 0.625rem 1.25rem;
                            background: $white;
                            width: auto!important;

                            .mm_menu_content_title {
                                color: $blue-dark;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }

    // submenus
    .mm_columns_ul_tab {
        font-weight: 500;
        transition: all 300ms ease-in-out;
        &:before {
            content: none;
            // display: block;
            // position: initial;
            // width: 350px;
            // height: 15px;
            // background: $white;
        }
        .mm_tabs_li {
            width: 350px;
            &.mm_tabs_has_content {
                display: flex;
                flex-direction: column;
            }
            .mm_tab_li_content {
                width: 100% !important;
                padding: 8px 5px;
                border: none;
                .mm_tab_toggle {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: 0.4375rem;
                    &:after,
                    &:before {
                        display: none;
                    }
                    .minus {
                        display: none;
                    }
                    > a[aria-expanded="true"] {
                        .plus {
                            display: none;
                        }
                        .minus {
                            display: inline-block;
                        }
                    }
                    .mm_tab_toggle_title {
                        padding: 2px 15px;
                        > a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 1.2;
                            color: $black;
                            font-size: 15px;
                            &:hover {
                                span {
                                    padding-left: 0.675rem;
                                }
                            }
                            span {
                                padding-left: 0;
                                transition: all .3s ease;
                            }
                        }
                        img {
                            width: 25px;
                            margin-right: 15px;
                        }
                    }
                }
            }
            &.last_category .mm_tab_li_content {
                padding-bottom: 1.4375rem;
            }

            // CATALOG PRODUCT LINK
            &:first-child {
                .mm_tab_li_content {
                    background: $wrapper-bg;
                    border-bottom: 3px solid rgba($black, 0.1);
                    .mm_tab_toggle {
                        .mm_tab_toggle_title {
                            display: block;
                            width: 100%;
                            text-align: center;
                            a {
                                color: $primary;
                                font-weight: bold;
                                font-size: 24px;
                                position: relative;
                                display: block;
                                span {
                                    display: block;
                                    &:hover {
                                        color: $tertiary;
                                    }
                                }
                                i {
                                    color: #9f9f9f;
                                    font-size: 18px;
                                    position: absolute;
                                    right: -8px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    margin: 0;
                                    &:hover {
                                        color: $tertiary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .mm_columns_contents_ul {
                visibility: visible;
                opacity: 1;
                width: auto !important;
                position: static !important;
                border: none;
                margin: 0;
                padding: 0;
                a:hover {
                    color: $tertiary;
                }
                .mm_blocks_li {
                    margin-bottom: 0;
                }
                .ets_mm_categories {
                    > li {
                        display: flex;
                        padding-left: 3.75rem;
                        position: relative;
                        // remove li disc
                        a {
                            line-height: 1.3;
                            font-size: 15px;
                            font-weight: 400;
                            margin-bottom: 10px;
                            &:before {
                                content: none;
                            }
                        }
                    }
                }
            }

            .mm_block_type_category {
                .h4 {
                    display: none;
                }
            }
        }
    }
    // }
}
@media (min-width: 992px) {
    #header {
        #accordion {
            position: fixed;
            left: 0;
            top: 0;
            height: 100%;
            width: fit-content !important;
            z-index: 1001;
            max-height: 100vh;
            overflow: auto;
            background-color: $white !important;
            transform: translateX(-100%);
            // firefox
            scrollbar-width: thin; /* "auto" or "thin" */
            scrollbar-color: $primary $wrapper-bg; /* scroll thumb and track */
            // chrome, edge, safari
            &::-webkit-scrollbar {
                width: 10px; /* width of the entire scrollbar */
            }
            &::-webkit-scrollbar-track {
                background: $wrapper-bg; /* color of the tracking area */
            }
            &::-webkit-scrollbar-thumb {
                background-color: $primary; /* color of the scroll thumb */
                border: none; /* creates padding around scroll thumb */
            }
            &.open-menu {
                visibility: visible;
                opacity: 1;
                pointer-events: auto;
                transform: translateX(0);
            }

            > li.mm_tabs_li:first-child {
                display: none;
            }

            > li.mm_tabs_li:last-child {
                background: $blue-dark;
                .mm_tab_li_content {
                    background-color: $blue-dark;
                    .mm_tab_toggle {
                        justify-content: center;
                        .mm_tab_toggle_title {
                            > a {
                                color: $white;
                                text-transform: uppercase;
                                font-size: 1rem;
                                font-weight: 600;
                            }
                        }
                    }
                    &:hover {
                        background-color: $yellow-dark;
                    }
                }

            }
        }
        .ets_mm_megamenu.layout_layout1 {
            &:before {
                inset: 0;
                position: fixed;
            }
            // First level - darkblue bar
            .ets_mm_megamenu_content {
                background: $primary;
                color: $white;
                padding: 0.625rem 1rem;
                .mm_menus_ul {
                    position: relative;
                    z-index: auto;
                    .mm_menus_li {
                        border: none;
                        height: 100%;
                        > a {
                            color: $white;
                            position: relative;
                            height: 100%;
                            &:before,
                            &:after {
                                content: "";
                                height: 5px;
                                width: 100%;
                                background-color: $tertiary;
                                position: absolute;
                                left: 0;
                                transition: none;
                                opacity: 0;
                            }
                            &:before {
                                top: 100%;
                            }
                            &:after {
                                bottom: 100%;
                            }
                        }
                        &:hover {
                            // override hover behavior for main menu
                            #accordion:not(.open-menu) {
                                opacity: 0;
                                visibility: hidden;
                            }
                            > a {
                                color: $yellow-dark;
                                background: transparent;
                            }
                        }
                        .mm_menu_content_title {
                            font-family: "Open Sans";
                            font-weight: 400;
                            font-size: 1rem;
                            text-transform: initial;
                            height: 100%;
                            line-height: 1;
                            display: flex;
                            align-items: center;
                            padding: 0;
                            transition: 300ms ease-in-out;
                            .fa-bars {
                                width: 1.25rem;
                                height: 1.3125rem;
                                overflow: hidden;
                                border-radius: 0;
                                margin-right: 0.625rem;
                                background: url("../img/burger.svg") center center no-repeat;
                                background-color: $white;
                                &:before {
                                    content: none;
                                }
                            }
                        }
                        // "Toujours ouvrir le menu vertical"
                        &.menu_ver_alway_show_sub {
                            .arrow {
                                display: none;
                            }
                            #accordion {
                                position: initial;
                                left: 0;
                                top: 0;
                                height: 0;
                                width: auto !important;
                            }
                        }
                    }
                }
            }
        }
        .ets_mm_megamenu.layout_layout1.bg_submenu {
            // First level - darkblue bar when main menu is opened
            .ets_mm_megamenu_content {
                .mm_menus_ul {
                    .mm_menus_li {
                        &:hover {
                            &.openable-menu > a {
                                background: inherit;
                                z-index: 8;
                                &:before,
                                &:after {
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 991px) {
    #header {
        box-shadow: 3px 6px 12px rgba($primary, 0.1);
        .ets_mm_megamenu.layout_layout1 {
            &.mobile_menu_opened::before {
                opacity: 1;
                visibility: visible;
                position: fixed;
                top: 0;
            }
            .mobile-menu-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 15px;
                background: $wrapper-bg;
                margin-bottom: 10px;
                .logo {
                    width: 75px;
                }
                span {
                    color: $primary;
                    position: relative;
                    font-size: 0.875rem;
                }
                .close-menu {
                    padding: 5px;
                    cursor: pointer;
                    &:hover {
                        color: $tertiary;
                    }
                }
            }
            .mm_menus_li {
                border: none;
                position: relative;
                > a {
                    padding: 1.25rem 0.9375rem;
                }
                .arrow {
                    height: 3.75rem;
                    right: 0.625rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:before,
                    &:after {
                        display: none;
                    }

                    .chevrondown {
                        transition: all .3s ease;
                    }

                    &.opened {
                        .chevrondown {
                            transform: rotate(180deg);
                        }
                    }
                }
                .mm_menu_content_title {
                    .fa-bars {
                        display: none;
                    }
                }
                &:nth-child(even) {
                    background: $white;
                }
                &:nth-child(odd) {
                    background: $wrapper-bg;
                }
            }
            // submenus
            .mm_columns_ul_tab {
                &:before {
                    content: none;
                }
                .mm_tabs_li {
                    // CATALOG LINK
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        background: $blue-dark;
                        .mm_tab_li_content {
                            background-color: $blue-dark;
                            .mm_tab_toggle {
                                justify-content: center;
                                .mm_tab_toggle_title {
                                    > a {
                                        color: $white;
                                        text-transform: uppercase;
                                        font-size: 1rem;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }

                    width: 100%;
                    &.primary,
                    &.secondary {
                        .mm_tab_li_content {
                            .mm_tab_toggle {
                                .mm_tab_toggle_title {
                                    > a {
                                        text-align: center;
                                        padding: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    #menu-icon {
        margin-right: 0;
        width: auto;
    }

    //// OPTI GOOGLE
    //#header .mm_columns_ul_tab .mm_tabs_li .mm_tab_li_content .mm_tab_toggle .mm_tab_toggle_title > a {
    //    font-weight: 700;
    //}
    //#header .mm_columns_ul_tab .mm_tabs_li .mm_tab_li_content .mm_tab_toggle .mm_tab_toggle_title > a,
    //#header .mm_columns_ul_tab .mm_tabs_li .mm_columns_contents_ul .ets_mm_categories > li a {
    //    font-size: .875rem;
    //    font-weight: 400;
    //}
}
