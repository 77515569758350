#checkout {
    .card-header {
        padding: 1.5rem 2.5rem;
        background-color: $white;
        box-shadow: none;
        @include media-breakpoint-down(sm) {
            padding: 1.125rem;
        }
    }
    .card-body {
        padding: 2.5rem;
        @include media-breakpoint-down(sm) {
            padding: 1.125rem;
        }
    }

    .socialcont-sep {
        margin-top: 0;
        margin-bottom: 15px;
        font-size: 18px;
    }
    .nav-tabs {
        display: flex;
        border: none;
        justify-content: flex-start;
        gap: 1.5rem;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
        }
        .nav-item {
            font-size: 14px;
            .nav-link {
                display: flex;
                font-weight: 700;
                color: $primary;
                align-items: center;
                justify-content: center;
                position: relative;
                padding: 0;
                margin: 0;
                border: none;
                transition: all .3s ease-in-out;

                &.active {
                    border-top: none;
                }

                span:first-child {
                    font-size: 1rem;
                    color: $primary;
                }
            }
        }
    }
    #js-checkout-summary {
        margin-top: 0;
        .cart-summary-products {
            .product-name {
                font-weight: bold;
                text-transform: uppercase;
                color: $primary;
                .product-quantity {
                    text-transform: none;
                }
            }
            .product-line-info {
                margin-bottom: 0;
                line-height: 1.1;
                label {
                    margin-bottom: 0;
                }
            }
            .product-price {
                font-weight: 600;
            }
        }
        .cart-summary-totals {
            margin-top: .5rem;
        }
    }
    .checkout-process {
        .checkout-step {
            border: none;
            &:not(.-current) .js-checkout-step-header {

            }
            .step-title {
                font-weight: bold;
                display: flex;
                align-items: center;
                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .step-number {
                    margin: 0 0.75rem 0 0;
                    min-width: 2.1875rem;
                    height: 2.1875rem;
                    color: $gray-600;
                    background: $white;
                    border: 1px solid $gray-300;
                    border-radius: 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1rem;
                    font-weight: 400;
                }
                .step-edit {
                    margin-left: auto;
                    text-transform: initial;
                    font-size: 1rem;
                    color: $black;
                }
            }
            &.-complete {
                .step-title {
                    color: $primary;

                    .step-number {
                        color: transparent;
                        background: url("../img/check.svg") center center no-repeat;
                        background-size: auto;
                        background-color: $success-light;
                        border: none;
                    }
                }
            }
            &.-reachable {
                .step-title:hover {
                    cursor: pointer;
                    color: $tertiary;
                }
            }
            &.-current {
                .step-title {
                    .step-number {
                        color: $white;
                        background: $green-dark;
                        border: none;
                    }
                }
            }
        }
    }
}

//.delivery-option > .row {
//    width: 100%;
//}

#content-hook_order_confirmation {
    position: relative;
    background-color: $white;
    padding: 0 15px;
    border-radius: 5px;
    margin-top: 30px;
    .card-title {
        text-transform: uppercase;
        display: flex;
        align-items: center;
        font-weight: bold;
        line-height: 1.25;
        i {
            margin-right: 5px;
            font-size: 36px;
        }
    }
    p {
        margin-bottom: 0;
    }
}

#order-confirmation {
    #order-items,
    #order-details,
    #content-hook_payment_return {
        background: $white;
    }
    #order-items {
        .order-items-heads {
            padding: 15px;
            .table__title-head {
                font-size: 1.25rem;
            }
        }
        .order-confirmation-table {
            border: none;
        }
    }
    #order-details {
        padding: 15px;
    }
    #content-hook-order-confirmation-footer {
        margin-top: 30px;
    }
}

.payment-options {
    .payment-option {
        border: 1px solid $gray-400;
        margin-bottom: 1.25rem;
        padding: 1.125rem;
    }
    .alert {
        color: $black;
    }
}

// < 992px
@include media-breakpoint-down(md) {}

// < 768px
@include media-breakpoint-down(sm) {
    #order-items {
        .order-items-heads {
            display: none;
        }
        .order-confirmation-table {
            .order-line {
                &:not(:last-of-type) {
                    .qty {
                        border-bottom: 1px solid rgba($black, .1);
                        padding-bottom: 1rem;
                    }
                }
                .details {
                    display: block;
                    font-weight: bold;
                    color: $primary;
                    text-transform: uppercase;
                    line-height: 1.3;
                }
            }
        }
        .bold {
            font-weight: bold;
        }
    }
    .checkout-form {
        .label {
            width: 100%;
        }
        .custom-control {
            &.custom-radio {
                padding: 0;
            }
        }
    }
    .payment-options {
        .payment-option {
            .custom-control.custom-radio {
                margin-top: 0;
                padding-left: 0;
            }
        }
    }
    .delivery-options-list {
        .delivery-option {
            .row {
                width: auto;
            }
            label {
                margin: 0;
            }

            .custom-radio {
                padding-left: 0;
                .col-2 {
                    padding: 0;
                }
                .col-10 {
                    padding-right: 0;
                }
            }

            .carrier-price {
                margin-top: 0.625rem;
                display: block;
            }
        }
    }
}