.products {
    @extend .row;
    align-items: stretch;
    .slick-list {
        margin: 0 0 1.25rem;

        .slick-slide {
            > div {
                height: 100%;
            }
        }

        .slick-dots {
            margin-top: 1.125rem;
        }
    }
}
#products .product-miniature {
    @extend .col-6;
}
@include media-breakpoint-desktop {
    #products .product-miniature,
    .products:not(.slick-initialized) .product-miniature {
        @include make-col(3);
    }

    #ybc_blog_page .products:not(.slick-initialized) .product-miniature {
        @include make-col(4);
    }

    .layout-full-width #products .product-miniature {
        @include make-col(3);
    }
}

@include media-breakpoint-down(md) {
    .products:not(.slick-initialized) .product-miniature {
        @include make-col(6);
    }
}
@include media-breakpoint-down(sm) {
    .products:not(.slick-initialized) .product-miniature {
        @include make-col(12);
    }
}

@media (max-width: 320px) {
    #products .product-miniature {
        @include make-col(12);
    }
}
.products .product-miniature {
    padding: 0 15px;
}
.slick-slider .product-miniature {
    max-width: 100%;
    height: 100%;
}
/* Product miniature*/
.card-product.card {
    height: 100%;
    position: relative;
    background-color: $pm-bg-color;
    border: none;
    border-top: 0.1875rem solid transparent;
    transition: all .3s ease;

    &:hover {
        border-color: $yellow-dark;
        .highlighted-informations {
            transform: translateY(0);
        }
    }
}

.card-img-top {
    overflow: hidden;
    .product-thumbnail img {
        width: 100%;
    }
}

.highlighted-informations {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    transform: translateY(100%);
    transition: transform 0.3s;
    .card-product:hover & {
        transform: translateY($pm-highlight-translateY);
    }
}
.quick-view {
    cursor: pointer;
}

.product-flags {
    position: absolute;
    top: 1.15rem;
    margin-bottom: 0;
    z-index: 1;
    left: 1.65rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: .5rem;
}

.product-flag {
    padding: .3125rem .625rem;
    border-radius: 1.5rem;
    color: #fff;
    background: $blue-dark;
    text-transform: initial;
    font-weight: 400;
    line-height: 1.25;

    &.discount-product {
        background: $green-dark;
    }
}
.color {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;
    background-size: contain;
}

.product-title {
    color: $pm-name-color;
    font-size: $pm-name-font-size;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: $pm-name-font-weight;
    margin-bottom: 0.625rem;
    & a {
        color: $pm-name-color;
        &:hover {
            color: $pm-name-hover-color;
            border-bottom: $pm-name-hover-border-width solid $pm-name-hover-border-color;
        }
    }
    .card-product:hover & {
        color: $pm-name-hover-color;
        & a {
            color: $pm-name-hover-color;
        }
    }
}

/* PRODUCT PAGE */

.products-imagescover {
    box-shadow: $card-box-shadow;
}
.product-img:not(:first-child) .rc {
    display: none;
}
.slick-initialized .product-img .rc {
    display: block;
}

.product-thumbs {
    // width:$product-thumb-wrap-width;
    height: $product-thumb-wrap-height;
    margin: 0;
    padding: 0 3.75rem;
    .slick-arrow {
        &.slick-prev {
            left: 0;
            transform: translate(0, -50%);
        }
        &.slick-next {
            right: 0;
            left: unset;
            transform: translate(0, -50%);
        }
        i {
            display: none;
        }
    }
    .slick-list {
        padding-bottom: 0;
    }
    .slick-slide {
        > div {
            cursor: pointer;
        }
    }
    &:not(.slick-initialized) > div:not(:first-child) {
        display: none;
    }
}
.product-thumbs  {
    height: auto;
}
.product-thumb {
    margin: $product-thumb-margin;
    //padding:5px;
    //height:  auto;
    width: $product-thumb-width;
    height: $product-thumb-height;
    border: 2px solid transparent;
    &.slick-current {
        border: 2px solid theme-color("primary");
    }

    @include media-breakpoint-down(sm) {
        width: 3.125rem;
        height: 3.125rem;
    }
}
.btn-zoom {
    position: absolute;
    right: $spacer;
    bottom: $spacer;
    cursor: pointer;
    display: none;
    padding: 0;
    line-height: 1;
    & .material-icons {
        font-size: 3 * $font-size-base;
    }
}
.images-container:hover .btn-zoom {
    display: block;
}
.label-color {
    line-height: 1;
    margin-right: $spacer/2;
}
.product-variants-item .color {
    width: 2rem;
    height: 2rem;
    border: 2px solid transparent;
}

.input-color:checked + span,
.input-color:hover + span,
.input-radio:checked + span,
.input-radio:hover + span {
    border-color: $colorbox-border-color;
}

//product prices
.product-price-and-shipping {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.product__product-price.has-discount {
    .current-price {
        display: inline-block;
    }
}
.price {
    color: $product-price-current-color;
    font-size: $product-price-current-font-size;
    font-weight: $product-price-current-font-weight;
    font-family: $product-price-current-font-family;
    &.current-price-discount {
        color: $product-price-discount-color;
        font-size: $product-price-discount-font-size;
        font-weight: $product-price-discount-font-weight;
        font-family: $product-price-discount-font-family;
    }
}
.regular-price {
    color: $product-price-regular-color;
    font-size: $product-price-regular-font-size;
    font-weight: $product-price-regular-font-weight;
    font-family: $product-price-regular-font-family;
    text-decoration: line-through;
}

//product add to cart
.btn-add-to-cart {
    //@extend .d-flex;
    text-transform: uppercase;
    font-weight: 600;
    white-space: nowrap;
}

//product tabs
.product-tabs {
    .nav-tabs {
        font-size: 1rem;
        justify-content: flex-start;
        border: none;
    }
    //@extend .card;
    //@extend .card-block
    //box-shadow:$card-box-shadow;;
}
.card--reassurance {
    box-shadow: $card-box-shadow;
}

// modal add to cart
.cart-content {
    padding: $spacer;
    background: theme-color("light");
}

//product pack
.pack-product-container {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
    padding: 0 $spacer;
    font-size: $font-size-sm;
}
.mask {
    width: 55px;
}
.thumb-mask {
    padding: $spacer 0;
}
.pack-product__img {
    border: 1px solid $border-color;
}
.pack-product-name {
    @extend .flex-grow-1;
    @extend .mx-4;
}
.pack-product-quantity {
    border-left: 1px solid $border-color;
}
.pack-product-price {
    padding-right: $spacer/2;
}
.pack-product-quantity {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .align-self-stretch;
    padding-left: $spacer;
}

//modal cart
.modal-cart__image {
    margin-right: $spacer;
    max-width: 200px;

    height: auto;
}
.modal-cart__name {
    color: $primary;
    font-weight: $font-weight-bold;
}
.product-total {
    font-weight: $font-weight-bold;
}
.cart-content {
    > p:not(:last-child) {
        margin-bottom: $spacer/2;
    }
}
@include media-breakpoint-mobile {
    .modal-cart__image {
        max-width: 120px;
    }
    .blockcart-modal {
        z-index: 10000000000;
        .divide-right {
            display: flex;
            justify-content: center;
        }
        .modal-footer {
            position: fixed;
            bottom: 0;
            background: #fff;
            justify-content: center;
            left: 0;
            width: 100%;
            box-shadow: 0px -0.25rem 0.5rem rgba($black, 0.15);
        }
        .modal-body {
            margin-bottom: ($btn-padding-y * 2) + ($btn-font-size * $btn-line-height) + $modal-inner-padding * 2;
        }
    }
}
@include media-breakpoint-down(xs) {
    .modal-cart__image {
        max-width: 70px;
    }
}
//spinner add to cart
.btn-add-to-cart {
    .btn-add-to-cart__spinner {
        @extend .spinner-border;
        width: $spinner-width-addtocart;
        height: $spinner-height-addtocart;
        border-width: $spinner-borderwidth-addtocart;
        display: none;
    }
    &.is--loading {
        .btn-add-to-cart__spinner {
            display: inline-block;
        }
        .btn-add-to-cart__icon {
            display: none;
        }
    }
}

.products-selection {
    justify-content: space-between;
    align-items: center;
}
.pack-miniature-item {
    margin-bottom: $spacer / 2;
}
.ratio1_1 {
    display: block;
}
.pack-product-name {
    margin: 0 $spacer * 1.5;
    flex-grow: 1;
}
.product-miniature {
    margin-bottom: $spacer;
    .card-body {
        // for Safari
        display: flex;
        width: 100%;
        height: 100%;
    }
    .product-description {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .product-price-and-shipping {
        margin-top: auto;
        gap: 0.5rem;
        .discount-percentage {
            font-size: 0.75rem;
            font-weight: 600;
        }
        .regular-price {
            font-size: 0.75rem;
            letter-spacing: -0.025rem;
            font-weight: 400;
            color: $black;
        }
        .price {
            font-size: 1.15rem;
            letter-spacing: -0.025rem;
            color: $blue-dark;
            font-weight: 700;
        }
    }
    .NETREVIEWS_PRODUCT_STARS {
        margin-top: auto;
        margin-bottom: 5px;
        + .product-price-and-shipping {
            margin-top: 0;
        }
    }
    div .plabel_div {
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
    }
    .add-to-cart {
        font-size: 1.025rem;
        width: 100%;
        margin-top: 1.25rem;
    }
}
.product__card-img {
    position: relative;
}
.product__card-desc {

}
.product-short-description {
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 1.25rem;
    p {
        margin-bottom: 0;
    }
}
.highlighted-informations {
    text-align: center;
    padding: $spacer / 2;
    display: flex;
    flex-wrap: wrap;
    .pureproductpictogram {
        padding: 0.3125rem 0.625rem;
        background: $gray-100;
        border-radius: 20px;
    }
}
.quantity__label {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: bold;
    margin-right: $spacer;
    // margin-top: $spacer;
}
.product-add-to-cart {
    .product-quantity {
        margin-top: -($spacer);
    }
}
.product-customization {
    margin-bottom: $spacer * 1.5;
}
.product-discounts {
    margin-bottom: $spacer * 1.5;
}
.product__quantity {
    margin-bottom: 0;
}
.sort-by-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.total__item-show {
    text-align: center;
    font-size: $font-size-sm;
    margin-top: $spacer;
}
.variant-links {
    display: flex;
    justify-content: center;
}
.product__download {
    text-transform: uppercase;
}
.card--address {
    height: 100%;
    text-align: center;
    justify-content: center;
}
.invoice__address {
    margin-top: $spacer;
}
.invoice-address {
    height: 100%;
    text-align: center;
    justify-content: center;
}
.nav-tabs {
    justify-content: center;
}

.featured-products {
    .slick-slider {
        .product-miniature {
            height: 100%;
        }
    }
    .slick-arrow {
        img, i {
            display: none;
        }
    }
}

.product-price-and-shipping {
    .av_category_stars {
        .ratingText {
            display: none;
        }
        .netreviews_review_rate_and_stars {
            width: 100px;
        }
    }
    .netreviews_bg_stars_big,
    .netreviews_review_rate_and_stars,
    .netreviews_bg_stars {
        span {
            margin: 0 2px !important;
        }
    }
}

#netreviews_reviews_tab {
    .netreviews_left_column {
        display: none;
        .netreviews_rating_content,
        .netreviews_logo {
            display: none !important;
        }
    }
    .netreviews_filtering_section,
    .netreviews_filtering_section2 {
        border-bottom: 1px solid $bg-dark;
        text-align: left;
        padding-bottom: 15px;
        select {
            margin-left: 5px;
            padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding)
                $custom-select-padding-y $custom-select-padding-x;
            font-family: $custom-select-font-family;
            font-weight: bold;
            font-size: 13px;
            line-height: $custom-select-line-height;
            color: rgba($black, 0.75) !important;
            vertical-align: middle;
            background: $bg-dark $custom-select-background;
            border: none;
            border-radius: 15px;
            box-shadow: none;
            appearance: none;

            &:focus {
                border-color: $custom-select-focus-border-color;
                outline: 0;

                &::-ms-value {
                    // For visual consistency with other platforms/browsers,
                    // suppress the default white text on blue background highlight given to
                    // the selected option text when the (still closed) <select> receives focus
                    // in IE and (under certain conditions) Edge.
                    // See https://github.com/twbs/bootstrap/issues/19398.
                    color: $input-color;
                    background-color: $input-bg;
                }
            }

            &[multiple],
            &[size]:not([size="1"]) {
                height: auto;
                padding-right: $custom-select-padding-x;
                background-image: none;
            }

            &:disabled {
                color: $custom-select-disabled-color;
                background-color: $custom-select-disabled-bg;
            }

            // Hides the default caret in IE11
            &::-ms-expand {
                display: none;
            }

            // Remove outline from select box in FF
            &:-moz-focusring {
                color: transparent;
                text-shadow: 0 0 0 $custom-select-color;
            }
        }
    }
    #netreviews_review_list {
        .netreviews_review_part {
            display: flex;
            flex-wrap: wrap;
            padding-top: 5px;
            border-top: 1px solid $bg-dark;
            .netreviews_review_stars {
                order: 0;
                width: fit-content;
                > div {
                    width: fit-content;
                }
                .nr-icon {
                    font-size: 16px;
                    &.gold {
                        color: #f68a65;
                    }
                }
            }
            .netreviews_customer_review {
                order: 2;
                margin-bottom: 5px;
            }
            .netreviews_customer_name {
                order: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 15px;
                > span:nth-child(2) {
                    display: none;
                }
            }
            .netreviews_helpful_block {
                order: 3;
                margin: 10px 0 0;
            }
        }
    }
}

.NETREVIEWS_PRODUCT_STARS .netreviews_review_rate_and_stars {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    height: auto;
    min-height: auto;
    > div {
        display: flex;
        position: inherit;
        font-size: inherit;
        &:nth-child(2) {
            margin-top: -18px; // height of star
        }
    }
    .nr-icon {
        font-size: 18px;
        padding: 0 2px;
        &.gold {
            color: $primary;
        }
    }
    .netreviews_reviews_rate {
        display: none;
    }
}

// < 768px
@include media-breakpoint-down(sm) {
    .featured-products {
        .slick-initialized {
            button.slick-arrow {
                top: 50% !important;
                &.slick-prev {
                    left: 0 !important;
                    right: auto !important;
                    padding-left: 0.75rem;
                    padding-right: 0;
                }
                &.slick-next {
                    right: 0 !important;
                    left: auto !important;
                    padding-right: 0.75rem;
                    padding-left: 0;
                }
            }
        }
    }
}
