.cms-id-4 {
    .page-header--cms {
        display: none;
    }
    h2 {
        text-transform: none;
        color: $white;
    }
    h3 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 32px;
        margin-bottom: 30px;
    }
    h4 {
        font-weight: bold;
        font-size: 20px;
    }
    em {
        font-weight: bold;
        color: $primary;
        font-style: normal;
    }
    .page-content--cms > h3 {
        margin-top: 30px;
    }
}

#cms #content {
    img {
        height: auto;
        max-width: 100%;
    }
    ul {
        list-style: disc;
        padding-left: 40px;
        li::marker {
            color: $primary;
        }
    }

    .cms-block {
        display: flex;
        flex-wrap: wrap;
        > * {
            width: 50%;
        }
        > img {
            height: auto;
            object-fit: cover;
            &:first-child {
                padding-right: 30px;
            }
            &:last-child {
                padding-left: 30px;
            }
        }
        + .cms-block {
            margin-top: 30px;
        }
    }
    .cms-block-text {
        &:first-child {
            padding-right: 30px;
        }
        &:last-child {
            padding-left: 30px;
        }
    }
    .cms-block-img-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .img-grid-item {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-size: 14px;
            line-height: 1.2;
            img {
                height: 75px;
            }
            &:last-child:nth-child(odd) {
                width: 75%;
            }
        }
        small, small > a {
            font-size: 13px;
            font-weight: bold;
            color: rgba($primary, .8);
        }
        small > a:hover {
            color: $tertiary;
        }
    }
    .cms-block-hours {
        text-align: center;
        display: flex;
        flex-direction: column;
        small {
            font-style: italic;
            font-weight: 600;
            line-height: 1.8;
            color: rgba($black, .9);
        }
    }
    .cms-partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .cms-partner {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img {
                height: 200px;
                max-width: 100%;
            }
            .cms-partner-name {
                color: $primary;
                font-size: 20px;
                font-weight: bold;
                margin-top: 5px;
            }
            .cms-partner-desc {
                font-weight: bold;
                color: $red;
            }
            .cms-partner-details {
                font-size: 13px;
            }
        }
    }
}

// < 992px
@include media-breakpoint-down(md) {
    #cms {
        #homeshop {
            margin-top: 30px;
        }
        #content {
            .cms-block {
                > * {
                    width: 100%;
                }
                > img {
                    margin-bottom: 30px;
                    &:first-child {
                        padding-right: 0;
                    }
                    &:last-child {
                        padding-left: 0;
                    }
                }
                .cms-block-text {
                    &:first-child {
                        padding-right: 0;
                        order: 1;
                    }
                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
            .cms-partners {
                .cms-partner {
                    img {
                        height: 125px;
                    }
                }
            }
        }
    }
}

// < 768px
@include media-breakpoint-down(sm) {}