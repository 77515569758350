.search-widget {
    max-width: 600px;
    margin: 0 auto;
}
.search-widget__group {
    position: relative;
}
.search-widget__input-right {
    font-size: 18px;
    padding: .625rem 3.5rem 0.625rem 0.625rem;
    height: 3.125rem;
    box-shadow: none;
    color: $primary;
    &::placeholder {
        color: $black;
        font-size: 1rem;
    }
}
.search-widget__btn {
    position: absolute;
    right: 0;
    top: 0;
    // background-color: rgba($secondary, 0.5);
    background-color: #96b5ba;
    height: 100%;
    .search {
        font-size: 32px;
    }
}
@include media-breakpoint-mobile {
    .search-widget {
        max-width: unset;
    }
}
