@mixin media-breakpoint-desktop() {

  @include media-breakpoint-up(lg) {
    @content;
  }
}
@mixin media-breakpoint-mobile() {

  @include media-breakpoint-down(md) {
    @content;
  }
}

@mixin generate-color-classes($color-map, $prefix) {
  @each $key, $color in $color-map {
    .#{$prefix}-#{$key} {
      color: $color;
    }
    .#{$prefix}-bg-#{$key} {
      background-color: $color;
    }
  }
}